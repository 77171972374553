import React from "react";
import {motion} from 'framer-motion'
import "./blinkCircle.css"
const BlinkCircle=(props)=>{
    const {topPosition,backgroundColor,leftPosition}=props
    return(
        <>
         <div className="blink-circle" style={{top:topPosition,left:leftPosition}}>
            <div className="blink-circle-parent" style={{background:backgroundColor}}>
                <motion.div 
                transition={{
                    opacity: {
                        duration:1,
                        yoyo:Infinity,
                        ease:"easeIn"
                    }
                }}
                animate={{opacity:["0%","50%"]}}
                className="blink-circle-middle" style={{background:backgroundColor}}>
                </motion.div>
                <motion.div
                transition={{
                    opacity: {
                        duration:1,
                        yoyo:Infinity,
                        ease:"easeIn"
                    }
                }}
                animate={{opacity:["0%","20%"]}}
                className="blink-circle-outer" style={{background:backgroundColor}}>        
                </motion.div>
            </div>
        </div> 
        </>
    );

};

export default BlinkCircle;