import React, { useEffect, useState } from "react";
import TopBar from "../TopBar";
import "../../CSS/HomePage/homePage.scss"
import "../../chatJs/chat"
import Services from "../Services/Services";
import Products from "../Products/Products";
import leftQuoteicon from "../../Assets/Elements/left-quote.svg"
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Feedbackimg from "../../Assets/Elements/man.jpg"
import facebookLogo from "../../Assets/Elements/Social Media/facebook-logo.svg"
import whatsAppLogo from "../../Assets/Elements/Social Media/whatsappLogo.svg"
import instagramLogo from "../../Assets/Elements/Social Media/instagram.svg"
import twitterLogo from "../../Assets/Elements/Social Media/twitterlogo.svg"
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Footer from "../Footer";
import Typewriter from 'typewriter-effect'
import manSvg from "../../Assets/Elements/HomePage/manHome.png"
import cloudleft from "../../Assets/Elements/HomePage/cloudleft.png"
import cloudright from "../../Assets/Elements/HomePage/cloudright.png"
import cloudbottom from "../../Assets/Elements/HomePage/cloudbottom.png"
import {motion} from 'framer-motion'
import Aos from 'aos'
import "aos/dist/aos.css"
import settingsIcon from "../../Assets/Elements/servicesIcons/Service.svg"
import UpClick from "../customComponents/UpClick";
import indianFlag from "../../Assets/Elements/Social Media/india (1).png"
import uaeFlag from "../../Assets/Elements/Social Media/united-arab-emirates.png"
import mailIcon from "../../Assets/Elements/Social Media/email.png"
import phoneIcon from "../../Assets/Elements/Social Media/smartphone-call.png"
import landphoneIcon from "../../Assets/Elements/Social Media/phone (1).png"
import tweetGreenIcon from "../../Assets/Elements/Social Media/tweetGreen.svg"
import fbGreenIcon from "../../Assets/Elements/Social Media/fbGreen.svg"
import linkedGreenIcon from "../../Assets/Elements/Social Media/linkedinGreen.svg"
import igGreenIcon from "../../Assets/Elements/Social Media/igGreen.svg"
import { HashLink } from "react-router-hash-link";
import { sendMessageAPICall } from "../../API/getInTouchAPI";
const HomePage=()=>{
    localStorage.setItem("productPage",false)
    const [reviewIndex,setReviewIndex]=useState(0)
    const messageFormInitialState={
        firstName:"",
        lastName:"",
        email:"",
        mobile:"",
        message:"",
    }
    const [messageForm,setMessageForm]=useState(messageFormInitialState)
    // validation states
    // const [validation, setValidation] = useState("");
    const getMsgForm=(key)=>(e)=>{
        const {value}=e.target

        if(key==="firstName"){
            setMessageForm({...messageForm,firstName:value})
        }
        if(key==="lastName"){
            setMessageForm({...messageForm,lastName:value})
        }
        if(key==="email"){
            setMessageForm({...messageForm,email:value})
        }
        if(key==="mobile"){
            setMessageForm({...messageForm,mobile:value})
        }
        if(key==="message"){
            setMessageForm({...messageForm,message:value})
        }
    }
    const clearForm=()=>{
        setMessageForm(messageFormInitialState)
    }
    const clickGetIntouch=()=>{
        // if(messageForm?.firstName===""){
        //     setValidation("fill this field")
        // }
        // else if(messageForm?.email===""){
        //     setValidation("fill this field")
        // }
        // else if(messageForm?.mobile===""){
        //     setValidation("fill this field")
        // }
        // else if(messageForm?.message===""){
        //     setValidation("fill this field")
        // }
        // else{
            sendMessageAPICall({
                firstName:messageForm?.firstName,
                lastName:messageForm?.lastName,
                emailAdress:messageForm?.email,
                mobile:messageForm?.lastNmobileame,
                message:messageForm?.message,
            },clearForm)
        // }
    }
    const [slideDirection,setSlideDirection]=useState("fade-left")
    useEffect(()=>{
        Aos.init({ duration:2000});
    },[]);


    const reviewSlide=(key)=>()=>{
        let lenghtOfarray=customerReviews?.length
        if (key==="left") {
            setSlideDirection("fade-right")
            if(reviewIndex===0){
                setReviewIndex(lenghtOfarray-1)
            }
            else{
                setReviewIndex(reviewIndex-1)
            }
            
        }
        if (key==="right") {
            setSlideDirection("fade-left")
            if(reviewIndex===lenghtOfarray-1){
                setReviewIndex(0)
            }
            else{
                setReviewIndex(reviewIndex+1)
            }
        }
    }
    const [messageSlide,setMessageSlide]=useState("")

    const customerReviews=[{
                            reviewrGroup:"White Group",
                            reviewr:"Nihad Ali",
                            feedBack:"We're thrilled with the impact EYE-T ERP has had on our textile business. It has simplified our inventory management, improved order fulfillment, and boosted our overall efficiency. Highly recommended!"

                        },
                        {
                            reviewrGroup:"Cyber Mound",
                            reviewr:"Shakir Kallungal",
                            feedBack:"The IT solutions provided by LeEYET Techno hub have transformed our organization. Their proactive approach to IT support, reliable infrastructure, and advanced security measures have given us peace of mind and allowed us to focus on our core business."
                                    //  LeEYET is our trusted IT partner, and we highly recommend their services."

                        }
                    ]   
        
        useEffect(() => {
            const timer = setTimeout(() => {
                const lengthOfArray = customerReviews?.length;
                setReviewIndex((prevIndex) => (prevIndex + 1) % lengthOfArray);
            }, 150000);
        
            return () => {
                clearTimeout(timer);
            };
            }, [reviewIndex]);                

    return(<>
        <TopBar />
        <div className="home-page-section">
        
        <section id="homepage">
        <div className="home-container">
            <div className="home-ctn-left">
                <div className="home-ctn-left-head">
                
                <h2 >TRANSFORMING IDEAS INTO ACTION  QUALITY BEYOND YOUR IMAGINATION
                <Typewriter
                    onInit={(typewriter)=>{
                        for(let i=0; i<120; i++){ 
                            typewriter
                            .typeString("TRANSFORMING IDEAS INTO ACTION QUALITY BEYOND YOUR IMAGINATION")
                            .pauseFor(2500)
                            .deleteAll(100)
                            .start();
                        }
                    }} >
                      
                   </Typewriter>
                    </h2>
                
                </div>
                <div className="home-ctn-left-body">
                    <p>Welcome to LeEYET Techno Hub, your destination for innovative technology solutions driving business growth and transformation.
                         We are a leading technology hub dedicated to empowering organizations with cutting-edge digital tools and strategies.
                         <br />
                        We believe in the power of technology to shape the future of businesses. Our mission is to enable businesses to thrive in the digital</p>
                </div>
                <div className="home-ctn-left-foot">
                    <div className="home-ctn-left-foot-btn">
                        <button><HashLink smooth to="#contactUs">Talk To Us</HashLink></button>
                    </div>
                    <div className="home-ctn-left-foot-icons">
                        <a href="https://twitter.com/LEEYE_T" target="_blank">
                        <img src={tweetGreenIcon} alt="" />
                        </a>
                        <a href="https://www.linkedin.com/in/leeye-t-techno-hub-llp" target="_blank">
                            <img src={linkedGreenIcon} alt="" />
                        </a>
                        <a href="https://ms-my.facebook.com/LeEYETtechnohub/" target="_blank">
                            <img src={fbGreenIcon} alt="" />
                        </a>
                        <a href="https://www.instagram.com/leeyet1/" target="_blank">
                            <img src={igGreenIcon} alt="" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="home-ctn-right">
                    <div data-aos="fade-up" className="home-ctn-right-colash">
                    
                        <motion.img 
                        transition={{
                            x: {
                              duration: 15,
                              yoyo: Infinity,  
                              type:"spring",
                            }
                          }}
                          animate={{ x: ["4vw", "26vw"] }}
                        src={cloudleft} className="cloud-left" alt="" />
                        <motion.img transition={{
                            x: {
                              duration: 15,
                              yoyo: Infinity,  
                              type:"spring",
                            }
                          }}
                          animate={{ x: ["4vw", "-20vw"] }} src={cloudright} className="cloud-right" alt="" />
                        <motion.img transition={{
                            x: {
                              duration: 15,
                              yoyo: Infinity,  
                              type:"spring",
                            }
                          }}
                          animate={{ x: ["0vw", "-20vw"] }} src={cloudbottom} className="cloud-bottom" alt="" />
                        <img src={manSvg} alt="" 
                        
                        className="man-home"/>
                    </div>
                    
            </div>
            
        </div>
        </section>

                    {/* serviceSection */}
                    {/* serviceSection */}
        
            <img data-aos="service-setting"
            src={settingsIcon} alt="" className="service-settings-icons" />
        <section  id="services">
        {/* <UpClick/> */}
            <Services/>
        </section>

                    {/* productsSection */}
                    {/* productsSection */}

        <section data-aos="fade-up" id="products">       
            <Products/>
            <UpClick topPosition='40%'/>
        </section>

        <img data-aos="service-setting-second"
            src={settingsIcon} alt="" className="service-settings-icons-second" />


                    {/* Home-bottom-section */}
                    {/* Home-bottom-section */}

        <div  className="home-bottom-section">
            <div data-aos="fade-down" className="home-bottom-section-one">

                <ArrowBackIosNewIcon
                 onClick={reviewSlide("left")} 
                 color="action"
                 sx={{fontSize:"30px",position:'absolute',top:'35%',left:'0%',cursor:"pointer"}}/>
                <ArrowForwardIosIcon
                 onClick={reviewSlide("right")} 
                 color="action"
                 sx={{fontSize:"30px",position:'absolute',top:'35%',right:'0%',cursor:"pointer"}}/>
                <img className="quote-overlay" src={leftQuoteicon} alt="" />
                    <div className="home-bottom-section-one-top">                         
                            <h2>WHAT OUR CUSTOMER SAY</h2>
                            <div data-aos={slideDirection} key={reviewIndex}>
                            <p 
                            >
                            {customerReviews[reviewIndex]?.feedBack}
                            </p>
                            <p
                             className="author-p"><span><sup> <img src={leftQuoteicon} alt="" /></sup></span>{customerReviews[reviewIndex]?.reviewr}</p>

                            </div>
                    </div>
                    <div className="home-bottom-section-one-bottom">
                            {/* <img src={Feedbackimg} alt="" /> */}
                            <p data-aos="fade-up" data-aos-duration="800" key={reviewIndex} style={{fontWeight:"700",fontSize:"1.3rem"}}>{customerReviews[reviewIndex]?.reviewrGroup}</p>
                            {/* <div className="home-bottom-section-one-bottom-icons-ctn">
                                <img  src={facebookLogo} alt="" />
                                <img  src={twitterLogo} alt="" />
                                <img  src={whatsAppLogo} alt="" />
                                <img  src={instagramLogo} alt="" />

                            </div> */}
                    </div>
            </div>

                       {/* Contact us Section  */}
                       {/* Contact us Section  */}


                <div id="contactUs"  className="home-bottom-section-two">
                    <UpClick topPosition='90%'/>
                    <div className="home-bottom-section-two-head">
                        <h2 data-aos="fade-left">Get In Touch</h2>
                        <p>Forge Strong Connections and Bring Your Vision to Life." Let’s work together. Fill us in with what you think and what you need. Get in Touch: Let's Connect and Make Things Happen."</p>
                    </div>
                    <div className="home-bottom-section-two-body">
                        <div className="home-bottom-section-two-body-section">
                            <input type="text" placeholder="First Name" 
                            value={messageForm?.firstName}
                            onChange={getMsgForm("firstName")}
                            />
                            <input type="text" placeholder="Last Name" 
                            value={messageForm?.lastName}
                            onChange={getMsgForm("lastName")}
                            />

                        </div>
                        <div className="home-bottom-section-two-body-section">
                            <input type="email" placeholder="Email Address" 
                            value={messageForm?.email}
                            onChange={getMsgForm("email")}
                            />
                            <input type="tel" placeholder="Phone Number"
                            value={messageForm?.mobile}
                            onChange={getMsgForm("mobile")}
                            />
                            </div>
                        <div className="home-bottom-section-two-body-section-message">
                       
                        <textarea name="" id="" placeholder="Message"
                            value={messageForm?.message}
                            onChange={getMsgForm("message")}
                        />
                        <button onClick={clickGetIntouch}>Get in Touch <span><NavigateNextIcon color="primary" sx={{backgroundColor:'#fff',borderRadius:"50%",fontSize:'1rem'}}/></span></button>
                        {/* <p className="validation-alert">{validation}</p> */}
                        </div>
                    </div>
                </div>


                <div className="home-bottom-location-container">
                    {/* <h2>Our Locations</h2> */}
                    <div className="home-bottom-location-section">
                        <div className="home-bottom-location-section-top">

                            <div className="home-bottom-location-ctn">
                                <div className="home-bottom-location-ctn-head">
                                    <img 
                                    data-aos="fade-left"
                                    src={indianFlag} 
                                    alt="" 
                                    // initial={{opacity:'0.2'}}
                                    // whileInView={{opacity:'1'}}
                                    // transition={{duration:5}}
                                    />
                                    <motion.p
                                    data-aos="fade-right"
                                    >India</motion.p>
                                </div>
                                    <h4>LeEYET Techno Hub LLP</h4>
                                    <p>2nd Floor,Sahya Building
                                        <br />
                                        Govt. Cyberpark, SEZ
                                        <br />Calicut, Kerala -673016
                                    </p>
                            </div>
                            <div className="home-bottom-location-ctn">
                                <div className="home-bottom-location-ctn-head">
                                    <img src={uaeFlag} alt="" data-aos="fade-left"/>
                                    <p data-aos="fade-right">UAE</p>
                                </div>
                                    <h4>LeEYET Techno hub FZC</h4>
                                    <p>SAIF ZONE,
                                      
                                            <br />
                                            SHARJAH - UAE
                                            <br />
                                    </p>
                            </div>
                        </div>
                        <div className="home-bottom-location-section-footer">
                            <div className="home-bottom-location-ctn-head" style={{margin:"1% 0 1% 7%"}} >
                                <div style={{display:"flex"}}>
                                  <img src={landphoneIcon} alt="" />
                                   <p>+91 495 293 3000</p>
                                </div>
                                <div style={{display:"flex"}}>
                                  <img src={phoneIcon} alt="" />
                                  <p>+91 8606644255</p>
                                </div>
                             
                            </div>
                          
                            <div className="home-bottom-location-ctn-head" style={{flexDirection:"row"}}>
                            
                               <img src={mailIcon} alt="" />
                                <p>support@leeyet.com</p>
                            </div>
                            <div className="home-bottom-location-ctn-head" style={{margin:"1% 12% 1% 0%"}}>
                               <div style={{display:"flex"}}>
                                  <img src={landphoneIcon} alt="" />
                                   <p style={{marginLeft:"2px"}}>+97 1556703165</p>
                                </div>
                                {/* <div style={{display:"flex"}}>
                                  <img src={phoneIcon} alt="" />
                                  <p style={{marginLeft:"2px"}}>00971556703165</p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    
                </div>
        </div>

                    {/* Footer-section */}
                    {/* Footer-section */}

        <Footer/>
       
        </div>
        </>
    );
};

export default HomePage;