import React, { useState } from "react";
import TopBar from "../TopBar";
import "../../CSS/Careers/careers.css"
import CareersCard from "./CareersCard";
import CloudIcon from '@mui/icons-material/Cloud';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Footer from "../Footer";
import {motion} from "framer-motion"
import carrersImage from "../../Assets/Elements/Careers/careeer-animation.gif"
import CloudUploadiIcon from "../../Assets/Elements/Careers/cloud-icon.png"
import resumeImage from "../../Assets/Elements/Social Media/file.png"
import { applyCareerAPICall } from "../../API/applyCareerAPI";
const Careers=()=>{

    const transition= {duration:1,type:'Inertia'}
    const [selectedCareer,setSelectedcareer]=useState("none")
    const [selectedFile,setSelectedFile]=useState(null)

    const mernStackInfo={
        jobTitle:"MERN Stack Developer",
        expDuration:"1-3 years",
        jobType:"Full Time",
        jobDescription:["Hands-on Experience and manage Node js, Mongo DB, React js and Express js.",
                        "Proficient in JavaScript & modern JavaScript.",
                        "Proficient in one or more libraries (e.g. Redux, React Router, etc) for state managing and routing in addition to React.",
                        "Should have experience working on both iOS and Android App. ",
                        "Must have familiarity working with Xcode & Android Studio.",
                        "Must have experience with offline storage on Mobile Apps.",
                        "Must have familiarity working with Firebase.",
                        ]
    }
    const reactNativeInfo={
        jobTitle:"React NATIVE Developer",
        expDuration:"1-3 years",
        jobType:"Full Time",
        jobDescription:["Hands on in rendering components with Native APIs.",
                        "Should work on the Front-end and Back-end processes.",
                        "Good understanding of DB architecture.",
                        "Experience in building and consuming REST API.",
                        "Monitor the application regularly and fix bugs"
                        ]
    }
    const nodeJsInfo={
        jobTitle:"Node JS Developer",
        expDuration:"0-2 years",
        jobType:"Full Time",
        jobDescription:["Knowledge of Node.js and frameworks available for it","Hands-on Experience and manage Node js, Mongo DB, React js and Express js.",
                        "Building backend for scalable Web and Mobile applications",
                        "Developing reusable code and libraries for future use",
                        "Optimization of the application for maximum availability and scalability",
                        "Collaborating with front-end developers on the integration of elements",
                        "Developing and maintaining all server-side network components.",
                        "User authentication and authorization between multiple systems, servers, and environments",
                        "Documenting Node.js processes, including database schemas, as well as preparing reports.",

                        ]
    }

    const clickUploadResume=(career)=>()=>{
        const section = document.querySelector('#uploadResume');
        const offset = 50; // Adjust the offset value as needed
        const top = section.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({ top: top - offset, behavior: 'smooth' });
        setSelectedcareer(career)
    }

    const uploadResumeBodyInitial={
        firstName:"",
        lastName:"",
        mobile:"",
        email:"",
        message:"",
        jobTitle:"",
        file:"",
    }
    const [uploadResumeBody,setUploadResumeBody]=useState(uploadResumeBodyInitial)
    const getFormInfo=(key)=>(e)=>{
        const {value}=e.target

        if(key==="firstName"){
            setUploadResumeBody({...uploadResumeBody,firstName:value})
        }
        if(key==="lastName"){
            setUploadResumeBody({...uploadResumeBody,lastName:value})
        }
        if(key==="mobile"){
            setUploadResumeBody({...uploadResumeBody,mobile:value})
        }
        if(key==="message"){
            setUploadResumeBody({...uploadResumeBody,message:value})
        }
        if(key==="email"){
            setUploadResumeBody({...uploadResumeBody,email:value})
        }
    }

    const handleFileUpload=(e)=>{
        setUploadResumeBody({...uploadResumeBody,file:e.target.files[0]})
        setSelectedFile(e.target.files[0])
    }
    const clickBrowseFiles=()=>{
        const fileInput = document.getElementById('fileInput');
        fileInput.click();
    }
    const handleDragEnter = (event) => {
        event.preventDefault();
        event.stopPropagation();
        event.currentTarget.classList.add('drag-over');
      };
    const handleDragLeave = (event) => {
        event.preventDefault();
        event.stopPropagation();
        event.currentTarget.classList.remove('drag-over');
      };  
    const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    };
    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setUploadResumeBody({...uploadResumeBody,file:event.dataTransfer.files[0]})
        event.currentTarget.classList.remove('drag-over');

        setSelectedFile(event.dataTransfer.files[0])
      };
    const removeSelectedFile=()=>{
        setSelectedFile(null)
        setUploadResumeBody({...uploadResumeBody,file:""})
    }


    const uploadPayload=new FormData()

    uploadPayload.append("file", uploadResumeBody?.file);
    uploadPayload.append("firstName", uploadResumeBody?.firstName);
    uploadPayload.append("lastName", uploadResumeBody?.lastName);
    uploadPayload.append("mobile", uploadResumeBody?.mobile);
    uploadPayload.append("message", uploadResumeBody?.message);
    uploadPayload.append("jobTitle", selectedCareer);
    uploadPayload.append("emailAddress", uploadResumeBody?.email);

    const clearState=()=>{
        setUploadResumeBody(uploadResumeBodyInitial)
        setSelectedFile(null)
        setSelectedcareer("none")
    }
    const clickApplyCareer=()=>{
        applyCareerAPICall(uploadPayload,clearState)
    }
    localStorage.setItem("productPage",false)
    return(
        <>
        <TopBar/>
        <motion.section
        initial={{opacity:0}}
        animate={{opacity:1}}
        exit={{opacity:0}}
        transition={transition}
        id="careers">  
        <div className="careers-container">
        
            <div className="careers-ctn-section-one">
                <div className="careers-ctn-section-one-left">
                    <h2>We've been <span style={{color:"blue"}}> Waiting </span>for you!</h2>
                    <p>
                    Join Our Family: Begin a Rewarding Career with us <br />
                    We believe in the power of talent and passion to drive innovation and success. We are constantly seeking talented individuals who are eager to make a difference, challenge the status quo, and contribute to our dynamic and collaborative team.
                    </p>
                </div>
                <div className="careers-ctn-section-one-right">
                    <img src={carrersImage} alt="" />
                </div>
            </div>

        

            <div className="careers-ctn-section-two">
            <div className="careers-ctn-section-two-head">
                <h2 style={{marginLeft:"2%"}}>Careers at LeEYE-T</h2>
                <p  style={{marginLeft:"2%"}}>"Don't watch the clock; do what it does. Keep going."</p>
            </div>
            <div className="careers-ctn-section-two-body">
            <div className="careers-ctn-section-two-body-ctn">
                    <CareersCard 
                        jobTitle={mernStackInfo?.jobTitle}
                        experienceDuration={mernStackInfo?.expDuration}
                        jobType={mernStackInfo?.jobType}
                        jobDescription={mernStackInfo?.jobDescription}
                        applyNowClick={clickUploadResume(mernStackInfo?.jobTitle)}
                        />
            </div>
            <div className="careers-ctn-section-two-body-ctn">
                    <CareersCard 
                        jobTitle={nodeJsInfo?.jobTitle}
                        experienceDuration={mernStackInfo?.expDuration}
                        jobType={mernStackInfo?.jobType}
                        jobDescription={mernStackInfo?.jobDescription}
                        applyNowClick={clickUploadResume(nodeJsInfo?.jobTitle)}
                    />
                    {/* <CareersCard />
                    <CareersCard />
                    <CareersCard />
                    <CareersCard /> */}
            </div>
            <div className="careers-ctn-section-two-body-ctn">
                    {/* <CareersCard />
                    <CareersCard />
                    <CareersCard /> */}
                    <CareersCard 
                        jobTitle={reactNativeInfo?.jobTitle}
                        experienceDuration={reactNativeInfo?.expDuration}
                        jobType={reactNativeInfo?.jobType}
                        jobDescription={reactNativeInfo?.jobDescription}
                        applyNowClick={clickUploadResume(reactNativeInfo?.jobTitle)}
                    />
            </div>

            </div>
                
            </div>
            <div className="careers-ctn-section-three" id="uploadResume">
                <div className="careers-ctn-section-three-head">
                    <h2 style={{marginLeft:"2%"}}>Upload your Resume</h2>
                </div>
                <div className="careers-ctn-section-three-body">
                <div className="careers-ctn-section-three-body-left">
                        <div className="careers-ctn-section-three-body-left-section">
                            <input 
                            value={uploadResumeBody?.firstName}
                            onChange={getFormInfo("firstName")}
                            type="text" placeholder="First Name"/>
                            <input
                            value={uploadResumeBody?.lastName}
                            onChange={getFormInfo("lastName")}
                            type="text" placeholder="Last Name" />
                        </div>
                        <div className="careers-ctn-section-three-body-left-section">
                            <input
                            value={uploadResumeBody?.email}
                            onChange={getFormInfo("email")}
                            type="text" placeholder="Email Address" />
                            <input
                            value={uploadResumeBody?.mobile}
                            onChange={getFormInfo("mobile")}
                            type="text" placeholder="Phone Number" />
                        </div>
                        <div className="careers-ctn-section-three-body-left-section-message">
                        <textarea 
                        value={uploadResumeBody?.message}
                            onChange={getFormInfo("message")}
                        name="" id="" placeholder="Message"/>
                        </div>      
                </div>
                <div 
                onDragEnter={handleDragEnter}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onDragLeave={handleDragLeave}
                className="careers-ctn-section-three-body-right">
                    {
                        selectedFile?
                        <div className="file-upload-ctn">
                            <img className="resume-img" src={resumeImage} alt="" />
                            <h4>{selectedFile.name} <span onClick={removeSelectedFile} className="delete-file-upload"><sup>X</sup></span></h4>
                        </div>
                        :
                        <div 
                        className="careers-ctn-section-three-body-right-ctn"
                        >
                        <h3 style={{marginLeft:"2%"}}>UPLOAD YOUR FILE</h3>
                        <motion.div
                        transition={{
                            y: {
                                duration:3,
                                yoyo:Infinity,
                                ease:'easeOut'
                            }
                        }}
                        animate={{y:["5%","-20%"]}}
                        className="three-body-right-ctn-icons"> 
                        <CloudIcon color="primary" sx={{fontSize:"2.5rem",alignSelf:'center'}}/>

                        <ArrowUpwardIcon color="primary" sx={{alignSelf:'center'}}/>
                        </motion.div>
                        <p style={{marginLeft:"2%"}}>Drag & Drop your files here</p>
                        <p style={{marginLeft:"2%"}}>OR</p>
                        <div className="three-body-right-ctn-btn">
                        <button onClick={clickBrowseFiles}>Browse Files</button>
                        <input id="fileInput" type="file" style={{display:"none"}} onChange={handleFileUpload}/>
                        </div> 
                    </div>
                    }

                    
                </div>

                </div>
                <div className="careers-ctn-section-three-foot">
                    <button onClick={clickApplyCareer} style={{marginLeft:"2%"}}>Apply Now</button>
                </div>
            </div>
        </div>
        </motion.section>
        <Footer/>
        </>
    );
}
export  default Careers;