import './App.css';
import HomePage from './Components/HomePage/HomePage';
import {BrowserRouter, Route,Routes} from "react-router-dom"
import Services from './Components/Services/Services';
import Products from './Components/Products/Products';
import About from './Components/About/About';
import Careers from './Components/Careers/Careers';
import ImageHome from './Components/HomePage/ImageHome';
import Blog from './Components/BlogPage/Blog';
import { ProductERP } from './Components/Products/ProductERP';
import { ProductOopacks } from './Components/Products/ProductOopacks';
import { ProductKoopa } from './Components/Products/ProductKoopa';
import { useEffect, useState } from 'react';
import logoIcon from "../src/Assets/logo/LEEYE-T-LLP.png"
import gitexImage from "../src/Assets/logo/GITEX copy 1.png"
import gitexImage1 from "../src/Assets/logo/GITEX.png"
import "../src/CSS/gitex.css"
function App() {

  const [loading,setIsLoading]=useState(true)
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);
  return (<>    

  {
    loading?
    <div className='loading-animation' id="loadingLogo">
        <img src={logoIcon} alt="Logo" />
    </div>
    :

  
   
     
      <BrowserRouter style={{position:"relative"}}> 
         <div>
         <Routes>
          <Route exact path='/' element={<HomePage/>}/>
          <Route path='/services' element={<Services/>}/>
          <Route path='/products' element={<Products/>}/>
          <Route path='/about' element={<About/>} />
          <Route path='/careers' element={<Careers/>} />
          <Route path='/blog' element={<Blog/>} />
          <Route path='/imghome' element={<ImageHome/>} />
          {/* product details */}
          <Route path="/products/erp" element={<ProductERP/>}/>
          <Route path="/products/oopacks" element={<ProductOopacks/>}/>
          <Route path="/products/koopa" element={<ProductKoopa/>}/>
        </Routes>
         </div>
         {/* <div className='gitex-container'>
        <p className='gitex-container-first-p'>MEET US AT</p>
        <span className='gitex-container-second-p'><i class="bi bi-geo-alt-fill location-icon"></i>WORLD TRADE CENTER,DUBAI</span>
        <img src={gitexImage1}></img>
        <p className='gitex-container-third-p'>16-20 OCT 2023</p>
        <p className='gitex-container-four-p' style={{marginTop:"4px"}}>HALL NO:H26</p>
        <p className='gitex-container-four-p'>C50-13</p>
      </div> */}
       
      </BrowserRouter>
   
    
}
  </>
  );
}

export default App;
