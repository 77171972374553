import React, { useEffect } from "react";
import "../../CSS/Products/productsInfo.css"
import TopBar from "../TopBar";
import Footer from "../Footer";
import bgImage from '../../Assets/Elements/productKoopa/Path 1455.png'
import '../../CSS/Products/productKoopa.css'
import rectangle1 from '../../Assets/Elements/productKoopa/Rectangle 1515.png'
import rectangle2 from '../../Assets/Elements/productKoopa/Rectangle 1514.png'
import rectangle3 from '../../Assets/Elements/productKoopa/Rectangle 1480.png'
import image1 from '../../Assets/Elements/productKoopa/tab 2 copy.png'
import image2 from '../../Assets/Elements/productKoopa/Group 8331.png'
import image3 from '../../Assets/Elements/productKoopa/Group 8616.png'
import image4 from '../../Assets/Elements/productKoopa/Group 8392.png'
import image5 from '../../Assets/Elements/productKoopa/Group 8374.png'
import {motion} from 'framer-motion'
import Aos from 'aos'
import "aos/dist/aos.css"


export const ProductKoopa=()=>{
  useEffect(()=>{
    Aos.init({ duration:2000,once: true});
},[]);

    return(
        <>
         <TopBar/>
         <div className="product-koopa-container">
           <div className="product-koopa-first-container">
              <img className="koopa-background-image"  src={bgImage} alt="" />
              <img className="koopa-rectangle-image-1" src={rectangle1} alt="" />
              <img className="koopa-rectangle-image-2"  src={rectangle2} alt="" />
              <img className="koopa-rectangle-image-3"  src={rectangle3} alt="" />
              <img 
               data-aos="flip-up"
           
              className="koopa-image-1" src={image1} alt="" />
              <div className="koopa-first-content">
                 <h1>KOOPA</h1>
                <p>Connect, create, secure, and Amplify Your Social Presence
                    Experience a new dimension of social networking with KOOPPA, the revolutionary social media application designed to empower individuals and businesses to connect, create, interact, and amplify their social presence. Join the KOOPPA community and take your social networking to the next level.</p>
              </div>
              <div className="koopa-side-heading">
                <h1>KOOPA</h1>
              </div>
           </div>
           <div className="product-koopa-second-container">
              <div className="product-koopa-second-left-border"></div>
              <div className="product-koopa-second-first-container">
                 <div className="product-koopa-second-first-container-contenet"> 
                    <h1>Social media platform</h1>
                    <p>Where Connections Flourish and Relationships Thrive
                        KOOPPA is a cutting-edge social media platform that offers a seamless and immersive environment for individuals and businesses alike. With its advanced features and intuitive interface, KOOPPA enables you to build meaningful connections, engage with your audience, and unleash your social potential.
                        Ready to Unleash Your Social Potential? Join KOOPPA Today!
                        Elevate your social networking experience with KOOPPA. Join our growing community and connect with friends, discover new opportunities, and amplify your social presence. Sign up and embark on a journey of meaningful connections, engaging interactions, and limitless possibilities. Experience social networking like never before with KOOPPA!</p>
                 </div>
                 <img  data-aos="flip-down" src={image2} alt="" />
              </div>
              <div className="product-koopa-second-second-container">
                 <img  data-aos="flip-up" src={image3} alt="" />
                 <div className="product-koopa-second-second-container-content">
                  <h1>Feeds</h1>
                  <p>Discover the Power of our feed : Connect, Share, and Engage Like Never Before
                     This is where social media comes alive with limitless possibilities. KOOPPA is not just another social media application; it's a vibrant community that connects people, amplifies voices, and sparks meaningful conversations. Join us on KOOPPA and experience the next generation of social networking.</p>
                 </div>
                
              </div>
              <div className="product-koopa-second-third-container">
                <div className="product-koopa-second-third-container-content">
                   <h1>Kooppa Business</h1>
                   <p>Amplify Your Brand and Connect with Your Audience
                    KOOPPA Business is your gateway to success in the digital realm. Connect with your audience, drive brand awareness, and foster customer engagement like never before. Join the thriving KOOPPA Business community and unlock endless opportunities for growth and success. With KOOPPA's powerful suite of business tools and a vibrant community of engaged users, you can take your brand to new heights, drive meaningful connections, and foster customer loyalty. KOOPPA Business offers an array of features designed to empower businesses of all sizes.</p>
                </div>
                <img data-aos="flip-down" src={image4} alt="" />
              </div>

              <div className="product-koopa-second-second-container">
                <img data-aos="flip-down" src={image5} alt="" />
                <div className="product-koopa-second-second-container-content">
                  <h1>UPI Payment</h1>
                  <p>Introducing UPI Payment on KOOPPA: Seamless and Secure Transactions Made Easy
                      UPI Payment KOOPPA’S upcoming feature, This secured feature that will revolutionize the way you transactions on KOOPPA. UPI Payment offers a seamless and secure payment experience, empowering you to make hassle-free transactions within the KOOPPA ecosystem.</p>
                 </div>
              </div>
              <div className="product-koopa-second-right-border"></div>
           </div>
         </div>
         <Footer/>
        </>
    )
}