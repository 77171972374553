import React, { useEffect } from "react";
import "../../CSS/Products/productsInfo.css"
import TopBar from "../TopBar";
import Footer from "../Footer";
import '../../CSS/Products/productErp.css'
import background from '../../Assets/Elements/erpProduct/Path 1455.png'
import erpImage from '../../Assets/Elements/erpProduct/mockup copy 1.png'
import {motion} from 'framer-motion'
import restaurantErp from '../../Assets/Elements/erpProduct/Group 8342.png'
import textileErp from '../../Assets/Elements/erpProduct/Group 8343.png'
import educationErp from '../../Assets/Elements/erpProduct/Group 8344.png'
import hotelErp from '../../Assets/Elements/erpProduct/Group 8345.png'
import logisticsErp from '../../Assets/Elements/erpProduct/Group 8346.png'
import aviationErp from '../../Assets/Elements/erpProduct/Group 8347.png'
import accounts from '../../Assets/Elements/erpProduct/Group 8712.png'
import pos from '../../Assets/Elements/erpProduct/Group 8713.png'
import inventory from '../../Assets/Elements/erpProduct/Group 8714.png'
import hrm from '../../Assets/Elements/erpProduct/Group 8715.png'
import sales from '../../Assets/Elements/erpProduct/Group 8716.png'
import purchaseMngr from '../../Assets/Elements/erpProduct/Group 8717.png'
import Aos from "aos";


export const ProductERP=()=>{
    const transition= {duration:2,type:'spring'} 
    useEffect(()=>{
        Aos.init({duration:2000,once: true})
    },[])
    return(
        <>
        <TopBar/>
        <section id="erp">
        <div className="erp-product-container">
            {/* section one */}
           <div className="erp-product-container-one">
            <img src={background} alt="" className="erp-product-background-clr"/>
            <motion.img
            data-aos="fade-down"
           
          
             src={erpImage} alt="" className="erp-product-image" 
            />
            <div className="erp-product-content">
                <p className="erp-product-content-one">EYE-T ERP</p>
                <p className="erp-product-content-two">Innovative ERP Solutions</p>
                <div className="erp-product-content-three">
                <p>Empowering Businesses with Next-Level Resource Planning
                 Are you ready to revolutionize your business operations and unlock unparalleled efficiency? Look no further than EYE-T ERP, the ultimate enterprise resource planning solution designed to streamline and optimize every aspect of your organization.</p>
                </div>
               
            </div>
           </div>
            {/* section two */}
           <div className="erp-product-container-two">
               <div className="erp-product-container-two-heading">
                  <motion.h1
                  initial={{top:'-50%'}}
                  whileInView={{top:'0%'}}
                  transition={transition}
                  >ERP</motion.h1>
                  <h2>ERP</h2>
               </div>
               <div className="erp-product-container-two-content">
                  <p>
                  With our cutting-edge EYE-T ERP software, you gain access to a comprehensive suite of tools that enable seamless management of your company's resources. From inventory and supply chain management to finance, human resources, and customer relationship management, EYE-T ERP empowers you to centralize, automate, and optimize your critical processes.
                  </p>
               </div>
           </div>
           {/* section three */}
           <div className="erp-product-container-three">
             <div className="erp-product-container-two-heading erp-product-container-three-heading" style={{margin:"1% 2%"}}>
                  <motion.h1
                  initial={{top:'-50%'}}
                  whileInView={{top:'0%'}}
                  transition={transition}
                  >TYPES OF ERP</motion.h1>
                  <h2>TYPES OF ERP</h2>
               </div>
               <div className="erp-product-types-of-erp-container">
                   <div className="erp-product-types-of-erp-container-left-border"></div>
                   <div className="erp-product-types-of-erp-single-card-container">
                   <motion.div 
                     whileHover={{ scale: 1.1, }}
                   className="erp-product-types-of-erp-single-card" style={{margin:"0 4% 2% 4%"}}>
                     <img src={restaurantErp} alt="" />
                     <h3>Restaurant ERP</h3>
                     <div className="erp-product-types-of-erp-single-card-content">
                     <p>Streamlining Operations for Success in the Hospitality Industry
                          In the fast-paced world of the restaurant industry, efficiency and organization are key to delivering exceptional customer experiences and driving profitability. A dedicated Restaurant ERP system is designed to meet the unique needs of restaurants, enabling streamlined operations, effective management, and enhanced decision-making.</p>
                     </div>
                   
                   </motion.div>
                   <motion.div 
                    whileHover={{ scale: 1.1, }}
                   className="erp-product-types-of-erp-single-card" style={{margin:"0 4% 2% 4%"}}>
                   <img src={textileErp} alt="" />
                     <h3>Textile ERP</h3>
                     <div className="erp-product-types-of-erp-single-card-content">
                     <p> Streamlining Operations for Success in the Dynamic Textile Industry
                        Progressing and ever-evolving textile industry, efficient resource management and streamlined operations are crucial for maximizing productivity and profitability. A dedicated Textile ERP system is specifically designed to address the unique challenges of textile companies, facilitating efficient processes, effective inventory management, and informed decision-making.</p>
                     </div>
                   </motion.div>
                   <motion.div 
                    whileHover={{ scale: 1.1, }}
                   className="erp-product-types-of-erp-single-card">
                   <img src={educationErp} alt="" />
                     <h3>Education ERP</h3>
                     <div className="erp-product-types-of-erp-single-card-content">
                     <p>Transforming Educational Institutions with Comprehensive Management Solutions
                           This Dynamic world of education, staying ahead requires efficient management of academic, administrative, and financial operations. A dedicated Education ERP system is specifically designed to meet the unique needs of educational institutions, offering comprehensive management solutions that streamline processes and enhance collaboration.</p>
                     </div>
                   </motion.div>
                   <motion.div 
                    whileHover={{ scale: 1.1, }}
                   className="erp-product-types-of-erp-single-card">
                   <img src={hotelErp} alt="" />
                     <h3>Hotel Management ERP</h3>
                     <div className="erp-product-types-of-erp-single-card-content">
                     <p> Streamlining Operations for Seamless Guest Experiences
                         In the ever- growing and competitive hospitality industry, efficient management of hotel operations is essential for delivering exceptional guest experiences and maximizing revenue. A dedicated Hotel Management ERP system is specifically designed to meet the unique needs of hotels and resorts, enabling streamlined operations, improved guest services, and effective resource management.</p>
                     </div>
                   </motion.div>
                   <motion.div 
                    whileHover={{ scale: 1.1, }}
                   className="erp-product-types-of-erp-single-card" style={{margin:"2% 4% 0 4%"}}>
                   <img src={logisticsErp} alt="" />
                     <h3>Logistics ERP</h3>
                     <div className="erp-product-types-of-erp-single-card-content">
                     <p>Streamlining Supply Chain Operations for Seamless Logistics Management
                       The fast-moving world of logistics and supply chain management, efficient coordination, and effective resource utilization are essential for meeting customer demands and maintaining a competitive edge. A dedicated Logistics ERP system is designed to meet the unique needs of logistics companies, enabling streamlined operations, enhanced visibility, and optimized logistics management.</p>
                     </div>
                   </motion.div>
                   <motion.div 
                    whileHover={{ scale: 1.1, }}
                   className="erp-product-types-of-erp-single-card" style={{margin:"2% 4% 0 4%"}}>
                   <img src={aviationErp} alt="" />
                     <h3>Aviation ERP</h3>
                     <div className="erp-product-types-of-erp-single-card-content">
                     <p>Optimizing Operations for Efficient Aviation Management
                         The Highly regulated aviation industry, efficient operations and compliance are paramount for maintaining safety, meeting regulatory requirements, and delivering exceptional service. A specialized Aviation ERP system is designed to meet the unique needs of aviation organizations, enabling streamlined operations, effective maintenance management, and enhanced decision-making.</p>
                     </div>

                   </motion.div>
                   </div>
                  
                   <div className="erp-product-types-of-erp-container-right-border"></div>

               </div>
           </div>
            <div className="erp-product-container-four">
           <div className="erp-product-container-two-heading erp-featture-heading">
                  <motion.h1
                  initial={{top:'-50%'}}
                  whileInView={{top:'0%'}}
                  transition={transition}
                  >ERP FEATURES</motion.h1>
                  <h2>ERP FEATURES</h2>
            </div>
            <div className="erp-product-container-erp-features">
              <div className="erp-product-container-erp-features-left">
                <div className="erp-product-container-erp-features-left-single">
                   <img src={accounts} alt="" />
                   <h3>Accounts</h3>
                </div>
                <div className="erp-product-container-erp-features-left-single">
                   <img src={pos} alt="" />
                   <h3>Point Of Sale</h3>
                </div>
                <div className="erp-product-container-erp-features-left-single">
                   <img src={inventory} alt="" />
                   <h3>Inventory</h3>
                </div>
              </div>
              <div className="erp-product-container-erp-features-right">
              <div className="erp-product-container-erp-features-left-single">
                   <img src={accounts} alt="" />
                   <h3>HRM & Payroll</h3>
                </div>
                <div className="erp-product-container-erp-features-left-single">
                   <img src={sales} alt="" />
                   <h3>Sales</h3>
                </div>
                <div className="erp-product-container-erp-features-left-single">
                   <img src={purchaseMngr} alt="" />
                   <h3>Purchase Manager</h3>
                </div>
              </div>
            </div>
          

           </div> 
        </div>
        </section>
       
         <Footer/>
        </>
    )
}