import React, { useEffect, useRef, useState } from "react";
import "../../CSS/Services/services.css"
import {motion} from 'framer-motion'
import numberOne from "../../Assets/Elements/servicesIcons/1.svg"
import numberTwo from "../../Assets/Elements/servicesIcons/2.svg"
import numberThree from "../../Assets/Elements/servicesIcons/3.svg"
import numberFour from "../../Assets/Elements/servicesIcons/4.svg"
import numberFive from "../../Assets/Elements/servicesIcons/5.svg"
import ClipboardIcon from "../../Assets/Elements/servicesIcons/file(1).svg"
import TopBar from "../TopBar";
const Services=()=>{

  localStorage.setItem("productPage",false)
   const transition= {duration:2,type:'spring'} 
   const [scrollClass,setScrollClass]=useState("services-ctn-body Flipped");
   const scrollContainerRef = useRef(null);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    const listenScrollEvent = () => {
      const scrollOffset = scrollContainer.scrollTop;
      const containerHeight = scrollContainer.clientHeight;
      const scrollHeight = scrollContainer.scrollHeight;

      const scrollPercentage = (scrollOffset / (scrollHeight - containerHeight)) * 100;

      if (scrollPercentage < 20) {
        setScrollClass('services-ctn-body Flipped');
      } else if (scrollPercentage < 40) {
        setScrollClass('services-ctn-body-two Flipped');
      } else if (scrollPercentage < 65) {
        setScrollClass('services-ctn-body-three Flipped');
      } else if (scrollPercentage < 85) {
        setScrollClass('services-ctn-body-four Flipped');
      } else {
        setScrollClass('services-ctn-body-five Flipped');
      }
    };

    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', listenScrollEvent);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', listenScrollEvent);
      }
    };
  }, []);
    return(
        <>
        <div className="services-container">
            {/* <button onClick={increment}>{count}</button> */}
            <div className="services-ctn-head">
                <div className="services-ctn-head-overlay">
                <motion.h1
                initial={{top:'-50%'}}
                whileInView={{top:'0%'}}
                transition={transition}
                >SERVICES</motion.h1>
                
                <h2>SERVICES</h2>
                </div>
                <p>
                We are bringing you an exceptional range of services tailored to the magnificent continent we call home. We understand the diverse and enchanting landscapes, cultures, and experiences that make this continent truly unique. Our services are crafted to capture the essence of this extraordinary place and bring it to you.
                </p>  
               
            </div>

            <div className={scrollClass} id="scroll-container" ref={scrollContainerRef}>
                <div className="services-ctn-body-section-left">
                    <div className="services-ctn-body-section-card-left">
                        <div className="services-ctn-body-section-card-bg-left">
                            <img src={ClipboardIcon} alt="" className="clipboardicon"/>
                        </div>
                          <motion.img
                          initial={{top:'-50%'}}
                          whileInView={{top:'5%'}}
                          transition={transition}
                          
                          src={numberOne} alt="" className="number-one" />  
                    </div>

                    <div className="services-ctn-body-section-detail-left">
                        <h2>Mobile App Development</h2>
                        <p>Mobile App Development team specialized in creating custom mobile applications that enhance user engagement, drive business growth, and provide a unique digital experience for your target audience.                          
                        </p>
                    </div>
                    </div>

                    <div className="services-ctn-body-section-right">              
                    <div className="services-ctn-body-section-detail-right">
                        <h2>Branding</h2>
                        <p>Need a captivating corporate identity? Our Corporate Designing services provide professional branding solutions, including logo design, brand guidelines, marketing collateral, and more to establish a strong brand presence and leave a lasting impression on your audience.
                        </p>
                    </div>

                    <div className="services-ctn-body-section-card-right">
                        <div className="services-ctn-body-section-card-bg-right">
                            <img src={ClipboardIcon} alt="" className="clipboardicon"/>
                        </div>
                          <motion.img
                          initial={{top:'80%'}}
                          whileInView={{top:'-25%'}}
                          transition={transition}
                          src={numberTwo} alt="" className="number-two" />  
                    </div>
                    </div> 

                    <div className="services-ctn-body-section-left">
                    <div className="services-ctn-body-section-card-left">
                        <div className="services-ctn-body-section-card-bg-left" style={{backgroundColor:'#827cdc',boxShadow:'rgb(130 124 220) 0px 3px 5px'}}>
                            <img src={ClipboardIcon} alt="" className="clipboardicon"/>
                        </div>
                          <motion.img
                           initial={{top:'-85%'}}
                           whileInView={{top:'55%'}} 
                           transition={transition}
                           src={numberThree} alt="" className="number-three" />  
                    </div>
                    <div className="services-ctn-body-section-detail-left">
                        <h2>Web Designing & Development</h2>
                        <p>
                        When it comes to Web Designing & Development, our experts craft visually stunning, responsive, and user-friendly websites tailored to your brand's identity and objectives. We prioritize functionality, aesthetics, and user experience to help your online presence stand out.
                        </p>
                    </div>
                    </div>   

                    <div className="services-ctn-body-section-right">  
                    <div className="services-ctn-body-section-detail-right">
                        <h2>Server and Hosting</h2>
                        <p>
                        Server and Hosting solutions ensure reliable and secure hosting for your website, guaranteeing seamless performance and minimal downtime.
                        </p>
                    </div>

                    <div className="services-ctn-body-section-card-right">
                        <div className="services-ctn-body-section-card-bg-right" style={{backgroundColor:'rgb(97 207 100)',boxShadow:' 0px 3px 5px rgb(97 207 100)'}}>
                            <img src={ClipboardIcon} alt="" className="clipboardicon"/>
                        </div>
                          <motion.img
                           initial={{top:'80%'}}
                           whileInView={{top:'-25%'}} 
                           transition={transition}
                          src={numberFour} alt="" className="number-four" />  
                    </div>
                    </div> 

                    <div className="services-ctn-body-section-left">
                    <div className="services-ctn-body-section-card-left">
                        <div className="services-ctn-body-section-card-bg-left" style={{backgroundColor:'rgb(248 167 254)',boxShadow:'0px 3px 5px rgb(248 167 254) '}}>
                            <img src={ClipboardIcon} alt="" className="clipboardicon"/>
                        </div>
                          <motion.img
                          initial={{top:'-50%'}}
                          whileInView={{top:'5%'}}
                          transition={transition}
                           src={numberFive} alt="" className="number-five" />  
                    </div>
                    <div className="services-ctn-body-section-detail-left">
                        <h2>Software Consulting</h2>
                        <p>
                       Software Consulting services offer expert guidance and strategic insights to optimize your technology infrastructure, improve operational efficiency, and align your business goals with the latest technological advancements.
                        </p>
                    </div>
                    </div>
           
            </div>
        </div>
        
        </>

    );
};

export default Services;