import axios from "axios";
import { HEADERS } from "./URLandPaths";

export const sendMessageAPICall=async(body,clear)=>{
    await axios.post("enquiry/addenquiry",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                clear()
            }
        })
}