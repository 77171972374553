import axios from "axios";
import {HEADER_FORM_DATA } from "./URLandPaths";

export const applyCareerAPICall=async(body,clear)=>{
    await axios.post("enquiry/addfileUpload",body,HEADER_FORM_DATA)
        .then((res)=>{
            if(res.status===200){
                clear()
            }
        })
}