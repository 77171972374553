import React, { useEffect, useState } from "react";
import LeeyetLogoPng from "../Assets/logo/LEEYE-T-LLP.png";
import { HashLink } from "react-router-hash-link";
import { NavHashLink } from "react-router-hash-link";
import "../CSS/TopBar/topbar.css"
import {FiMenu,FiX} from "react-icons/fi"
import "aos/dist/aos.css"
import Aos from "aos";

const TopBar=()=>{
  
  const [homeLinkState,setHomeLinkState]=useState(false)
  const [servicesLinkState,setServicesLinkState]=useState(false)
  const [productLinkState,setProductLinkState]=useState(false)
  const [contactLinkState,setContactLinkState]=useState(false)
  const [aboutClickState,setAboutClickState]=useState(false)
  const [careerClickState,setCareerClickState]=useState(false)
     

  const [menuOpen,setMenuOpen]=useState(false)
  const menuClicked=()=>setMenuOpen(!menuOpen)

  let toggleHome= homeLinkState ? 'active':'inactive';
  let toggleServices= servicesLinkState ? 'active':'inactive';
  let toggleProducts= productLinkState ? 'active':'inactive';
  let toggleContact=contactLinkState? 'active' :'inactive'
      
  const productViewInfo=localStorage.getItem("productPage")
  console.log(productViewInfo);
  const listenScrollEvent = event => {
        if(aboutClickState  === false && careerClickState ===false && productViewInfo==="false"){
          
            if(window.scrollY<580){
                setHomeLinkState(true)
                setServicesLinkState(false)
                setProductLinkState(false)
                setContactLinkState(false);
             }
             else if(window.scrollY>620 && window.scrollY<1380){
                setServicesLinkState(true)
                setHomeLinkState(false)
                setProductLinkState(false)
                setContactLinkState(false);
             }
             else if(window.scrollY>1381 && window.scrollY<3490){
                setProductLinkState(true)
                setServicesLinkState(false)
                setHomeLinkState(false)
                setContactLinkState(false);
             }
             else if(window.scrollY>4000 ){
                setProductLinkState(false)
                setServicesLinkState(false)
                setHomeLinkState(false)
                setContactLinkState(true);
             }
            }
             else {
              setProductLinkState(false)
              setServicesLinkState(false)
              setHomeLinkState(false)
              setContactLinkState(false);
             }
            }
          const homeLinkClicked=()=>{
            setHomeLinkState(true);
            setProductLinkState(false);
            setServicesLinkState(false);
            setContactLinkState(false);
            setAboutClickState(true)
            setMenuOpen(false)
            }
          const productLinkClicked=()=>{
            setProductLinkState(true);
            setHomeLinkState(false);
            setServicesLinkState(false);
           
            setContactLinkState(false);
            setAboutClickState(true)
            setMenuOpen(false)
          }
          const contactLinkClicked=()=>{
            setContactLinkState(true)
            setProductLinkState(false);
            setHomeLinkState(false);
            setServicesLinkState(false);
           
           
            setAboutClickState(true)
            setMenuOpen(false)
          }
          const servicesLinkClicked=()=>{
            setServicesLinkState(true);
            setProductLinkState(false);
            setHomeLinkState(false);
            setContactLinkState(false);
            setAboutClickState(true)
            setMenuOpen(false)
          }
          useEffect(()=>{ 
            window.addEventListener("scroll",listenScrollEvent);
              },[aboutClickState,careerClickState])     
            useEffect(()=>{
                Aos.init({ duration:1000});
            },[]);
            return(
                <>
                <div className="top-bar-parent">
                  <div className="top-bar-parent-left">
                    <HashLink smooth to="/#top">  
                      <img src={LeeyetLogoPng} alt="" />
                    </HashLink>
                  </div>
                  <div className="top-bar-parent-responsive-switch">
                    {menuOpen?<FiX size={50} data-aos="fade-down" onClick={menuClicked}/>:
                    <FiMenu  size={30} data-aos="zoom-out" onClick={menuClicked}/>}
                  </div>
                  {menuOpen?<div data-aos="fade-down" className="top-bar-parent-responsive-switch-menu">
                  <ul>
                  <li>
                  <HashLink smooth to="/#top" onClick={homeLinkClicked} className={`home-${toggleHome}`}>HOME</HashLink>
                  </li>
                  <li>
                    <HashLink smooth to="/#services" onClick={servicesLinkClicked} className={`services-${toggleServices}`} >SERVICES</HashLink>
                  </li>
                  <li>
                  <HashLink smooth to="/#products" onClick={productLinkClicked} className={`products-${toggleProducts}`}>PRODUCTS</HashLink>
                  </li>
                  <li>
                    <NavHashLink smooth to="/about#top" style={({isActive})=>isActive?{fontWeight:"800"}:{textDecoration:"none"}}  className={({isActive})=>isActive? setAboutClickState(true):setAboutClickState(false)}>ABOUT</NavHashLink>
                  </li>
                  <li>
                    <NavHashLink smooth to="/careers#top"
                     style={({isActive})=>isActive?{fontWeight:"800"}:{textDecoration:"none"}}  className={({isActive})=>isActive? setCareerClickState(true) :setCareerClickState(false)}
                     >CAREERS</NavHashLink>
                  </li>
                  <li>
                  <NavHashLink smooth to="/blog">BLOG</NavHashLink>
                  </li>
                  <li>
                  <HashLink  smooth to="/#contactUs" onClick={contactLinkClicked} className={`contact-${toggleContact}`}>CONTACT</HashLink>
                  </li>
                </ul>
                            </div>:null}
                  
                  <div className="top-bar-parent-right">
                  
                  <ul>
                  <li>
                  <HashLink smooth to="/#top" onClick={homeLinkClicked} className={`home-${toggleHome}`}>HOME</HashLink>
                  </li>
                  <li>
                    <HashLink smooth to="/#services" onClick={servicesLinkClicked} className={`services-${toggleServices}`} >SERVICES</HashLink>
                  </li>
                  <li>
                  <HashLink smooth to="/#products" onClick={productLinkClicked} className={`products-${toggleProducts}`}>PRODUCTS</HashLink>
                  </li>
                  <li>
                    <NavHashLink smooth to="/about#top" style={({isActive})=>isActive?{fontWeight:"800"}:{textDecoration:"none"}}  className={({isActive})=>isActive? setAboutClickState(true):setAboutClickState(false)}>ABOUT</NavHashLink>
                  </li>
                  <li>
                    <NavHashLink smooth to="/careers#top"
                     style={({isActive})=>isActive?{fontWeight:"800"}:{textDecoration:"none"}}  className={({isActive})=>isActive? setCareerClickState(true) :setCareerClickState(false)}
                     >CAREERS</NavHashLink>
                  </li>
                  <li>
                  <NavHashLink smooth to="/blog">BLOG</NavHashLink>
                  </li>
                  <li>
                  <HashLink smooth to="/#contactUs" onClick={contactLinkClicked} className={`contact-${toggleContact}`}>CONTACT</HashLink>
                  </li>
                </ul>
                    </div>
                </div>
                </>
            );
        };


        export default TopBar;
