import React from "react";
import { NavLink } from "react-router-dom";
import "../CSS/footer.css"
import facebookLogo from "../Assets/Elements/Social Media/facebook-logo.svg"
import whatsAppLogo from "../Assets/Elements/Social Media/whatsappLogo.svg"
import instagramLogo from "../Assets/Elements/Social Media/instagram.svg"
import twitterLogo from "../Assets/Elements/Social Media/twitterlogo.svg"
import youTubeLogo from "../Assets/Elements/Social Media/yt icon.svg"
import linkedin from '../Assets/Elements/Social Media/linkedin_2_-01.svg'
import { HashLink } from "react-router-hash-link";
const Footer=()=>{


    return (
        <>
        <div className="footer"> 
        <div className="footer-container">
            <div className="footer-ctn-section">
                <ul>
                    <li className="head">OUR MENUS</li>
                    <li>
                        <HashLink smooth to='/#top'>Home</HashLink>
                    </li>
                    <li>
                        <HashLink smooth to='/#services'>Our Services</HashLink>
                    </li>
                    <li>
                        <HashLink smooth to='/#products'>Our Products</HashLink>
                    </li>
                    <li>
                        <HashLink smooth to='/about#top'>About Us</HashLink>
                    </li>
                    <li>
                        <HashLink smooth to='/careers#top'>Careers
                         {/* <span><p>We're hiring</p></span> */}
                         </HashLink>
                    </li>
                    <li>
                        <HashLink smooth to='/blog'>Blog</HashLink>
                    </li>
                    <li>
                        <HashLink smooth to='/#contactUs'>Contact Us</HashLink>
                    </li>
                </ul>
            </div>
           
           
            <div className="footer-ctn-section">
            <ul>
                    <li className="head">PRODUCTS</li>
                    <li>OOPACKS</li>
                    <li>ERP</li>
                    <li>CRM</li>
                    <li>HRM</li>
                    <li>E-Commerce</li>
                </ul>
            </div>
            <div className="footer-ctn-section">
                <ul>
                    <li className="head">OUR SERVICES
                    </li>
                    <li>Web Designing & Development</li>
                    <li>Mobile App Development</li>
                    <li>Software Consulting</li>
                    <li>Corporate Designing</li>
                </ul>
            </div>
            <div className="footer-ctn-section">
            <ul>
                    <li className="head">ABOUT COMPANY</li>
                    <li><p> LeEYE-T ® is focus to deliver high quality, 
                            cost-effective, time bound and value 
                            added services to meet a wide range of 
                            requirements in order to help clients 
                            yield greater return on investment for 
                            their technology initiatives.
                    </p></li>
                    <li><div className="footer-section-icons-ctn standard-view-icons">
                                <div className="footer-section-icons-img-bg">    
                                <a href="https://ms-my.facebook.com/LeEYETtechnohub/" target="_blank" rel="noopener noreferrer" style={{justifyContent:'center'}}><img  src={facebookLogo} alt="" /></a>
                                </div>
                                <div className="footer-section-icons-img-bg">    
                                <a href="https://twitter.com/LEEYE_T" target="_blank" style={{justifyContent:'center'}}><img  src={twitterLogo} alt="" /></a>
                                </div>
                                {/* <div className="footer-section-icons-img-bg">    
                                <a href="https://twitter.com/LEEYE_T" target="_blank" style={{justifyContent:'center'}}><img  src={whatsAppLogo} alt="" /></a>
                                </div> */}
                                <div className="footer-section-icons-img-bg">    
                                <a href="https://www.instagram.com/leeyet1/" target="_blank" style={{justifyContent:'center'}}><img  src={instagramLogo} alt="" /></a>
                                </div>
                                <div className="footer-section-icons-img-bg">    
                                <a href="https://www.linkedin.com/in/leeye-t-techno-hub-llp" target="_blank" style={{justifyContent:'center'}}><img  src={linkedin} alt="" /></a>
                                </div>
                                {/* <div className="footer-section-icons-img-bg">    
                                <a href="https://twitter.com/LEEYE_T" style={{justifyContent:'center'}}><img src={youTubeLogo} style={{width:"95%"}} alt="" /></a>
                                </div> */}
                        </div></li>
                </ul>
            </div>
            <div className="footer-section-icons-ctn icons-mobile-view">
                                <div className="footer-section-icons-img-bg">    
                                <a href="https://ms-my.facebook.com/LeEYETtechnohub/" target="_blank" rel="noopener noreferrer" style={{justifyContent:'center'}}><img  src={facebookLogo} alt="" /></a>
                                </div>
                                <div className="footer-section-icons-img-bg">    
                                <a href="https://twitter.com/LEEYE_T" target="_blank"  style={{justifyContent:'center'}}><img  src={twitterLogo} alt="" /></a>
                                </div>
                                {/* <div className="footer-section-icons-img-bg">    
                                <a href="https://twitter.com/LEEYE_T" target="_blank" style={{justifyContent:'center'}}><img  src={whatsAppLogo} alt="" /></a>
                                </div> */}
                                <div className="footer-section-icons-img-bg">    
                                <a href="https://www.instagram.com/leeyet1" target="_blank" style={{justifyContent:'center'}}><img  src={instagramLogo} alt="" /></a>
                                </div>
                                <div className="footer-section-icons-img-bg">    
                                <a href="https://www.linkedin.com/in/leeye-t-techno-hub-llp"target="_blank"  style={{justifyContent:'center'}}><img  src={linkedin} alt="" /></a>
                                </div>
                                {/* <div className="footer-section-icons-img-bg">    
                                <a href="https://twitter.com/LEEYE_T" style={{justifyContent:'center'}}><img src={youTubeLogo} style={{width:"95%"}} alt="" /></a>
                                </div> */}
                        </div>
        </div>
        
        <div className="footer-bottom">
            <p>LeEYE-T Techno Hub | All rights reserved. 2023 | OOPACKS and EYE-T are registered trademarks of LeEYE-T Techno Hub LLP</p>
            <p>Terms & Conditions-Privacy Policy</p>
        </div>
        </div>

        </>
    );
};

export default Footer;