import React,{useEffect} from "react";
import "../../CSS/Products/productsInfo.css"
import TopBar from "../TopBar";
import '../../CSS/Products/productOopacks.css'
import background from '../../Assets/Elements/oopacksProduct/Path 1455.png'
import laptop from '../../Assets/Elements/oopacksProduct/lap 1.png'
import Aos from "aos";
import {motion} from 'framer-motion'
import oopacksMobile from '../../Assets/Elements/oopacksProduct/Group 8313.png'
import image1 from '../../Assets/Elements/oopacksProduct/Group 8323.png'
import image from '../../Assets/Elements/oopacksProduct/Group 8322.png'
import { useState } from "react";
import selectProduct from '../../Assets/Elements/oopacksProduct/Product.png'
import addCart from '../../Assets/Elements/oopacksProduct/Cart.png'
import payment from '../../Assets/Elements/oopacksProduct/Payment.png'
import Buy from '../../Assets/Elements/oopacksProduct/Buy Now.png'
import Footer from "../Footer";
export const ProductOopacks=()=>{
    const transition= {duration:4,type:'spring'} 
    const [cart,setCart]=useState("selectYorProduct")
    useEffect(()=>{
        Aos.init({duration:1000,once: true})
    },[])

    const selectYorProductclick=()=>{
        document.getElementById("selectYorProduct").classList.add("bold-p")
        document.getElementById("addToCartProduct").classList.remove("bold-p")
        document.getElementById("selectYorPayment").classList.remove("bold-p")
        document.getElementById("buyNow").classList.remove("bold-p")
        setCart("selectYorProduct")
    }
    const addToCartclick=()=>{
        document.getElementById("selectYorProduct").classList.remove("bold-p")
        document.getElementById("addToCartProduct").classList.add("bold-p")
        document.getElementById("selectYorPayment").classList.remove("bold-p")
        document.getElementById("buyNow").classList.remove("bold-p")
        setCart("addToCartProduct")
    }
    const selectYorPaymentlick=()=>{
        document.getElementById("selectYorProduct").classList.remove("bold-p")
        document.getElementById("addToCartProduct").classList.remove("bold-p")
        document.getElementById("selectYorPayment").classList.add("bold-p")
        document.getElementById("buyNow").classList.remove("bold-p")
        setCart("selectYorPayment")
    }
    const buyNowclick=()=>{
        document.getElementById("selectYorProduct").classList.remove("bold-p")
        document.getElementById("addToCartProduct").classList.remove("bold-p")
        document.getElementById("selectYorPayment").classList.remove("bold-p")
        document.getElementById("buyNow").classList.add("bold-p")
        setCart("buyNow")
    }
    return(
        <>
        <TopBar/>
        <div className="product-oopacks-conatiner">
         <img src={background} alt="" className="oopacks-background-image"/>
         <img 
           data-aos="fade-down"
           src={laptop} alt="" className="oopacks-laptop-image"/>
           <div className="oopacks-main-content">
            <h1>OOPACKS</h1>
            <p>Redefining Fashion Retail with Innovation and Style
               Discover a new era of online shopping with OOPACKS, the cutting-edge ecommerce application designed to empower businesses and deliver exceptional digital experiences to customers. Seamlessly integrate, manage, and grow your online store with OOPACKS. OOPACKS- the world's first online tailors gateway, where revolutionary changes in fashion design come to life. Our OOPACKS Ecommerce Application is a game-changer in the fashion retail industry, 
               offering a seamless and immersive online shopping experience like never before.</p>
           </div>
            <div className="oopacks-side-heading">
            <h1>OOPACKS</h1>
            <img 
           data-aos="fade-down"
            src={oopacksMobile} 
            alt="" />
            <div className="oopacks-product-second-heading">
                <p> Focus on what makes you different and Unleash Your Style with us
                  OOPACKS redefines the way you shop for fashion by connecting you directly with expert tailors and designers from around the world. Our Ecommerce Application combines cutting-edge technology with artistic craftsmanship, empowering you to create bespoke garments that perfectly reflect your unique style and preferences.OOPACKS is a feature-rich ecommerce platform that offers a comprehensive suite of tools and functionalities to help you establish and scale your online business. 
                  Ready to Elevate Your Ecommerce Business? Contact Us Today!</p>
            </div>

           </div>

           <div className="oopacks-product-last-container">
             <div className="oopacks-product-last-container-left-border"></div>
             <div className="oopacks-product-last-container-top-container">
             <div className="oppacks-product-last-container-content">
                <p>Take your online business to its deep depths with OOPACKS. Our dedicated team is ready to guide you through the setup and customization process, ensuring a seamless transition and maximum impact for your ecommerce venture. With its intuitive interface and advanced capabilities, OOPACKS sets the stage for success in the competitive world of ecommerce.
                   Contact us today to schedule a demo or learn more about how OOPACKS can transform your online store and drive success in the digital marketplace. Experience the power of next-level ecommerce with OOPACKS!</p>
             </div>
             <img  data-aos="flip-up" src={image} alt=""  className="oopacks-last-container-image"/>
             <img data-aos="flip-left" src={image1} alt="" className="oopacks-last-container-image1"/>
             </div>
             <div className="oopacks-product-last-container-second-container">
               <div className="oopacks-product-last-container-second-cart-container">
                   <div className="oopacks-product-last-container-second-cart-container-left">
                      <div className="oopacks-product-last-container-second-cart-container-left-heading">
                        <motion.h1
                          initial={{top:'-50%'}}
                          whileInView={{top:'0%'}}
                          transition={transition}
                        >Transforming Fashion</motion.h1>
                        <h2>Transforming Fashion</h2>
                      </div>
                      <div style={{display:"flex",flexDirection:"column",textAlign:"start"}}>
                        <p className="oopacks-product-last-container-second-cart-container-left-heading1">OOPACKS trademark as the world’s first online tailors gateway,
                        seeking to bring revolutionary changes in fashion design.</p>
                        <p className="oopacks-product-last-container-second-cart-container-left-heading2">Embrace innovation and elevate your wardrobe with OOPACKS, where fashion meets the future!</p>
                      </div>
                      <div className="oopacks-payment-methods">
                        <p onClick={selectYorProductclick} id="selectYorProduct" className="non-bold bold-p">1.&nbsp;&nbsp;SELECT YOUR PRODUCT</p>
                        <p onClick={addToCartclick} id="addToCartProduct" className="non-bold">2.&nbsp;&nbsp;ADD TO CART</p>
                        <p onClick={selectYorPaymentlick} id="selectYorPayment" className="non-bold">3.&nbsp;&nbsp;SELECT YOUR PAYMENT METHOD</p>
                        <p onClick={buyNowclick} id="buyNow" className="non-bold">4.&nbsp;&nbsp;BUY NOW</p>
                      </div>
                      <div style={{display:"flex",justifyContent:"flex-end"}}>
                      
                        <hr className="oopacks-cart-last-text-border"/>
                        <p className="oopacks-cart-last-text">Seamless access</p>
                      </div>
                   </div>
                   <div className="oopacks-product-last-container-second-cart-container-right">
                      {
                        cart==="selectYorProduct"?
                        <img src={selectProduct}/>
                        :
                        cart==="addToCartProduct"?
                        <img src={addCart}/>
                        :
                        cart==="selectYorPayment"?
                        <img src={payment}/>
                        :
                        cart==="buyNow"?
                        <img src={Buy}/>
                        :undefined
                      }
                   </div>
               </div>
             </div>
             <div className="oopacks-product-last-container-right-border"></div>
          </div>
           
        </div>
        <Footer/>
       
       
        </>
    )
}