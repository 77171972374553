import axios from "axios";

//  axios.defaults.baseURL= process.env.REACT_APP_LOCAL_BASEURL
axios.defaults.baseURL= process.env.REACT_APP_PRODUCTION_BASEURL

export const HEADERS ={
    headers:{
        "Content-Type":"application/json",
    },
};

export const HEADER_QUERY ={
    "Content-Type":"application/json"
};

export const HEADER_FORM_DATA ={
    headers:{
       "Content-Type":"multipart/form-data",
    },
};