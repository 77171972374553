import React, { useEffect } from "react";
import "../../CSS/Products/products.css"
import MobileScreen2Svg from "../../Assets/Elements/ProductsPage/Mobile-SCree-2.png";
import MobileSCreenSvg from "../../Assets/Elements/ProductsPage/Mobile-SCreen.png";
import ActiveUserIconSvg from "../../Assets/Elements/activeUsersIcon.svg"; 
import LeadsIconSvg from "../../Assets/Elements/leadsIcon.svg";
import TabScreenImg from "../../Assets/Elements/ProductsPage/Tab-SCreen.png";
import OopacksImg from "../../Assets/Elements/ProductsPage/oopacks.png"
import OopacksMobileImg from "../../Assets/Elements/ProductsPage/OOpacks-mobile.png"
import ProductsBackground from "../../Assets/Elements/ProductsPage/homeBackground.png"
import RedCircle from "../../Assets/Elements/ProductsPage/redCircle.png"
import GreenCircle from "../../Assets/Elements/ProductsPage/greenCircle.png"
import BlueCircle from "../../Assets/Elements/ProductsPage/BlueCircle.png"
import { motion } from "framer-motion";
import Aos from 'aos'
import "aos/dist/aos.css"
import {useNavigate} from "react-router-dom"
import { HashLink } from "react-router-hash-link";

const Products=()=>{
    const navigate = useNavigate();
    localStorage.setItem("productPage",false)
    useEffect(()=>{
        Aos.init({ duration:2000,once: true});
    },[]);

    const clickErpReadMore=()=>{
       localStorage.setItem("productPage",true)
    }


    return(
        <>
       

        <img src={ProductsBackground} className="products-container-product-background" alt="" />
        <div className="products-container">
            <div className="products-container-header">
                {/* <h2>Products</h2> */}
                <div className="products-ctn-head-overlay">
                    <motion.h1
                    initial={{top:'-50%'}}
                    whileInView={{top:'0%'}}
                    transition={{duration:2,type:"spring"}}
                    >PRODUCTS</motion.h1>
                    
                    <h2>PRODUCTS</h2>
                </div>
                <h2 className="header-gradient">Embrace Innovation, Empower Success:<br />
                Discover the LeEYET Advantage</h2>
            </div>
            
            <div  className="products-container-body"> 
            <div data-aos="fade-up" className="products-ctn-left">
                <div  className="products-ctn-left-head">
                    <h2>Our Flagship Product, 
                    <br /> EYE-T ERP</h2>
                    <p>
                        Revolutionizes enterprise resource planning by providing a comprehensive suite of tools for efficient management of your organization's resources. With EYE-T ERP, you can streamline operations, enhance productivity, and achieve optimal efficiency across various departments.
                    </p>
                </div>

                <div className="products-ctn-left-body">
                    <div className="products-ctn-left-body-icons">
                    <div className="products-ctn-left-body-section">
                         <img src={ActiveUserIconSvg} alt="" /> <p style={{color:'#008d11'}}>---</p>
                    </div>
                    <div className="products-ctn-left-body-section">
                    <img src={LeadsIconSvg} alt="" /> <p style={{color:'#EE156B'}}>---</p>
                    </div>
                    </div>
                    <div className="products-ctn-left-body-btn">
                        {/* <button onClick={clickErpReadMore}>Read More</button> */}
                        <button><HashLink smooth to="/products/erp#top" onClick={clickErpReadMore}>Read More</HashLink></button>
                    </div>
                
                </div>

            </div>
            <div  className="products-ctn-right">
            <div data-aos="fade-right" className="products-ctn-left-section">
                <div className="products-ctn-left-section-circle-red"></div>
                <div className="products-ctn-left-section-circle-violet"></div>
                <img className="tabScreen" src={TabScreenImg} alt="" />
            </div>
            </div>
            </div>

            <div className="products-container-body">

            <div className="products-ctn-left">
            <div className="products-ctn-right-section">
                    <img src={RedCircle} alt=""  style={{position:'absolute',top:'10%',left:'7%',width:'60%'}}/>
                    <img data-aos="flip-down" className="screen1" src={MobileScreen2Svg} alt="" />
                    <img data-aos="flip-up" className="screen2" src={MobileSCreenSvg} alt="" />
                </div>
            
            </div>
            <div data-aos="fade-up" className="products-ctn-body-two-right">
                <div className="products-ctn-body-two-right-section">
                    <img src={GreenCircle} alt="" style={{position:'absolute',top:'-35%',left:'40%',width:'90%'}}/>
                <div className="products-ctn-left-head">
                    <h2>Introducing KOOPPA</h2>
                    <p>
                    Groundbreaking social media application that takes social networking to new heights. KOOPPA offers a user-friendly interface, advanced features, and powerful networking capabilities, allowing you to connect, interact, and engage with your friends, colleagues, and target audience seamlessly.
                    </p>
                </div>
                <div className="products-ctn-left-body products-ctn-left-body-right">
                     <div className="products-ctn-left-body-icons">
                    <div className="products-ctn-left-body-section">
                         <img src={ActiveUserIconSvg} alt="" /> <p style={{color:'#008d11'}}>---</p>
                    </div>
                    <div className="products-ctn-left-body-section">
                    <img src={LeadsIconSvg} alt="" /> <p style={{color:'#EE156B'}}>---</p>
                    </div>
                    </div>
                    <div className="products-ctn-left-body-btn">

                        {/* <button>read more</button> */}

                    <button><HashLink smooth to="/products/koopa#top">Read More</HashLink></button>

                    </div>
                
                </div>
                </div>
            </div>

            </div>

            <div className="products-container-body"> 
            <div data-aos="fade-right" className="products-ctn-left">
                <div className="products-ctn-left-head products-ctn-left-head-third-body">
                    <h2>Experience the future of <br />E-commerce with OOPACKS 
                    </h2>
                    <p>
                    Cutting-edge ecommerce application. OOPACKS empowers businesses to establish a robust online presence, manage inventory effectively, facilitate secure transactions, and provide an exceptional shopping experience for customers.
                    </p>
                </div>

                <div className="products-ctn-left-body">
                    <div className="products-ctn-left-body-icons">
                    <div className="products-ctn-left-body-section">
                         <img src={ActiveUserIconSvg} alt="" /> <p style={{color:'#008d11'}}>---</p>
                    </div>
                    <div className="products-ctn-left-body-section">
                    <img src={LeadsIconSvg} alt="" /> <p style={{color:'#EE156B'}}>---</p>
                    </div>
                    </div>
                    <div className="products-ctn-left-body-btn">
                        <button><HashLink smooth to="/products/oopacks#top">Read More</HashLink></button>
                    </div>
                        
                </div>



            </div>
            <div data-aos="fade-up" className="products-ctn-right">
            <div className="products-ctn-right-section">
            <img src={BlueCircle} alt="" style={{position:'absolute',top:'0%',left:'-28%',width:'50%'}}/>
                <img data-aos="fade-out" className="oopacks-img" src={OopacksImg} alt="" />
                <motion.img
                initial={{rotate:'0deg'}}
                whileInView={{rotate:'-13deg'}}
                transition={{duration:1}}
                className="oopacks-mobile-img" src={OopacksMobileImg} alt="" />
            </div>
            </div>
            </div>
        </div>
        </>
    );
};

export default Products;