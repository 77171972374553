import React from "react";
import TopBar from "../TopBar";
import '../../CSS/Blog/Blog.css'
import {motion} from 'framer-motion'
import comingSoon from '../../Assets/Elements/104575-coming-soon.gif'

const Blog=()=>{

    localStorage.setItem("productPage",false)
    return(
        <>
        <TopBar/>
        <div className="blog-contaier">
           {/* <motion.p
           animate={{scale:2}}
           transition={{duration:2}}

           >COMING SOON!</motion.p> */}
           <img src={comingSoon}></img>
        </div>
       
        </>
    )
}

export default Blog