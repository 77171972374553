import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import "./imageHome.css"       
        const ImageHome=()=>{
        const [appState,setAppState]=useState({
            activeObject:null,
            objects:[{id:1},{id:2},{id:3},{id:4}]
        })
       
        return(
            <div className="App">
                {appState.objects.map((elements,index)=>(
                    <div key={index} className="box inactive" 
                    // onClick={}
                    ></div>
                ))}
               
            </div>
        );
    };

    export default ImageHome;