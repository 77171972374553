import React, { useEffect, useState } from "react";
import TopBar from "../TopBar";
import "../../CSS/About/about.css"
import buildingImg from "../../Assets/Elements/About/Rectangle 68.jpg";
import paperBoat from "../../Assets/Elements/About/Rectangle 71.jpg";
import ceoImg from "../../Assets/Elements/ceoimg.jpg";
import Footer from "../Footer";
import  CenterLine from "../../Assets/Elements/About/centerline.svg"
import BlinkCircle from "../customComponents/BlinkCircle";
import {motion} from "framer-motion"
import aboutBackground from "../../Assets/Elements/About/about-background.png"
import aboutBg from "../../Assets/Elements/About/about-bg.png"
import aboutDot from "../../Assets/Elements/About/about-dot.png"
import aboutInbox from "../../Assets/Elements/About/about-inbox.png"
import aboutMen from "../../Assets/Elements/About/about-men.png"
import aboutMsg from "../../Assets/Elements/About/about-msg.png"
import aboutPhone from "../../Assets/Elements/About/about-phone.png"
import aboutWeb from "../../Assets/Elements/About/about-web.png"
import aboutCloud1 from "../../Assets/Elements/About/about-cloud1.png"
import aboutCloud2 from "../../Assets/Elements/About/about-cloud2.png"
import aboutCloud3 from "../../Assets/Elements/About/about-cloud3.png"
import aboutCloud4 from "../../Assets/Elements/About/about-cloud4.png"
import aboutCloud5 from "../../Assets/Elements/About/about-cloud5.png"
import aboutArrowSingle from "../../Assets/Elements/About/about-arrow-single.png"

import aboutLadyArrow from "../../Assets/Elements/About/ladyarrow.svg"
import aboutSparkleArchery from "../../Assets/Elements/About/sparkles/about-sparkle-archery.png"
import aboutSparkleArrow1 from "../../Assets/Elements/About/sparkles/about-sparkle-arrow1.png"
import aboutSparkleArrow2 from "../../Assets/Elements/About/sparkles/about-sparkle-arrow2.png"
import aboutSparkleDiamond1 from "../../Assets/Elements/About/sparkles/about-sparkle-diamond.svg"
import aboutSparkleDiamond3 from "../../Assets/Elements/About/sparkles/about-sparkle-diamond.svg"
import aboutSparkleDiamond2 from "../../Assets/Elements/About/sparkles/about-sparkle-diamond.svg"
import aboutSparkleDiamond4 from "../../Assets/Elements/About/sparkles/about-sparkle-diamond.svg"
import aboutSparkleDiamondMedium from "../../Assets/Elements/About/sparkles/about-sparkle-diamond-medium.svg"
import aboutSparkleDiamondSmall1 from "../../Assets/Elements/About/sparkles/about-sparkle-diamond-small.svg"
import aboutSparkleDiamondSmall2 from "../../Assets/Elements/About/sparkles/about-sparkle-diamond-small.svg"
import aboutSparkleDiamondSmall3 from "../../Assets/Elements/About/sparkles/about-sparkle-diamond-small.svg"
import aboutSparkleDiamondSmall4 from "../../Assets/Elements/About/sparkles/about-sparkle-diamond-small.svg"
import aboutSparkleDot1 from "../../Assets/Elements/About/sparkles/about-sparkle-dot.svg"
import aboutSparkleDot2 from "../../Assets/Elements/About/sparkles/about-sparkle-dot.svg"
import aboutSparkleDotMedium1 from "../../Assets/Elements/About/sparkles/about-sparkle-dot-medium.svg"
import aboutSparkleDotMedium2 from "../../Assets/Elements/About/sparkles/about-sparkle-dot-medium.svg"
import aboutSparkleDotMedium3 from "../../Assets/Elements/About/sparkles/about-sparkle-dot-medium.svg"
import aboutSparkleDotMedium4 from "../../Assets/Elements/About/sparkles/about-sparkle-dot-medium.svg"
import aboutSparkleDotMedium5 from "../../Assets/Elements/About/sparkles/about-sparkle-dot-medium.svg"
import aboutSparkleDotMedium6 from "../../Assets/Elements/About/sparkles/about-sparkle-dot-medium.svg"
import aboutSparkleDotMedium7 from "../../Assets/Elements/About/sparkles/about-sparkle-dot-medium.svg"
import aboutSparkleDotMedium8 from "../../Assets/Elements/About/sparkles/about-sparkle-dot-medium.svg"
import aboutSparkleDotMedium9 from "../../Assets/Elements/About/sparkles/about-sparkle-dot-medium.svg"
import aboutSparkleDotSmall1 from "../../Assets/Elements/About/sparkles/about-sparkle-dot-small.svg"
import aboutSparkleDotSmall2 from "../../Assets/Elements/About/sparkles/about-sparkle-dot-small.svg"
import aboutSparkleDotSmall3 from "../../Assets/Elements/About/sparkles/about-sparkle-dot-small.svg"
import aboutSparkleDotSmall4 from "../../Assets/Elements/About/sparkles/about-sparkle-dot-small.svg"
import aboutSparkleDotSmall5 from "../../Assets/Elements/About/sparkles/about-sparkle-dot-small.svg"
import aboutSparkleDotSmall6 from "../../Assets/Elements/About/sparkles/about-sparkle-dot-small.svg"
import aboutSparkleDotSmall7 from "../../Assets/Elements/About/sparkles/about-sparkle-dot-small.svg"
import aboutSparkleDotSmall8 from "../../Assets/Elements/About/sparkles/about-sparkle-dot-small.svg"
import aboutSparkleDotSmall9 from "../../Assets/Elements/About/sparkles/about-sparkle-dot-small.svg"
import PaperPlaneSvg from "../../Assets/Elements/About/bottom-section/transparent paperplane.svg"
import readMoreIcon from "../../Assets/Elements/Social Media/more.png"
import readLessIcon from "../../Assets/Elements/Social Media/minimize-arrows.png"

import Aos from "aos";
import "aos/dist/aos.css";

// const svgVariants={
//     hidden:{},
//     visible:{
        
//         transition:{duration:1 }
//     }
// }

// const pathVariants={
//     hidden:{
//         pathLength:0
//     },
//     visible:{
//         opacity:0.5,
//         pathLength:1,
//         transition:{
//             duration:5,
//             ease:"easeInOut"
//         }
//     }
// }

const About=()=>{
    localStorage.setItem("productPage",false)
    const [readMore,setReadMore]=useState(false)

    useEffect(()=>{
        Aos.init({duration:1000,once: true})
    },[])
    const sparkleDiamondSmallTransition={y: {duration:2,yoyo:Infinity,ease:'easeIn'}}
    const sparkleDiamondSmallAnimation={y:["0%","90%"]}
    const sparkleDotMediumTransition={y: {duration:2,yoyo:Infinity,ease:'easeIn'}}
    const sparkleDotMediumAnimation={y:["0%","90%"]}
    const sparkleDotTransition={duration:2,yoyo:Infinity,type:'spring'}
    const sparkleDotAnimation={width:["0px","2px"]}
    const sparkleDotLargeAnimation={width:["4px","12px"]}
    return(
        <>
        <TopBar/>
        <section id="about"> 
            <div className="about-container" style={{backgroundImage:"none"}}>
                <img src={CenterLine} className="about-center-line" alt="" style={{position:'absolute',top:'21%',left:'50%',height:'60%'}} />
                <div className="about-top-section">
                   
                    <div className="about-top-section-body-ctn">
                        <div className="about-top-section-body-ctn-left-one">
                            <div className="about-top-section-body-ctn-left-one-colash" style={{position:'relative',marginTop:'5%'}}>
                                
                                    <motion.img
                                    initial={{rotate:'10deg'}}
                                    whileInView={{rotate:'0deg'}}
                                    transition={{duration:0.5}}
                                    src={aboutBg} style={{width:'75%'}} alt="" />
                                    <motion.img
                                    initial={{opacity:0,width:'30%'}}
                                    whileInView={{opacity:1,width:'50%'}}
                                    transition={{duration:0.5}}
                                    src={aboutMen} style={{width:'50%',position:'absolute',top:'-26%',left:'11%'}} alt="" />
                                    <img src={aboutDot} style={{width:'6%',position:'absolute',top:'6%',left:'78%'}} alt="" />
                                    <motion.img 
                                    transition={{
                                        y: {
                                            duration:5,
                                            yoyo:Infinity,
                                            ease:"easeIn"
                                        },
                                        rotate: {
                                            duration:9,
                                            yoyo:Infinity
                                        }
                                    }}
                                    animate={{y:["5%","-15%"],rotate:["-40deg","0deg"]}}
                                    src={aboutInbox} style={{position:'absolute',width:'9%',left:'75%',top:'-14%'}} alt="" />
                                    <motion.img 
                                    transition={{
                                        y: {
                                            duration:3,
                                            yoyo:Infinity,
                                            ease:"easeIn"
                                        }
                                    }}
                                    animate={{y:["5%","-25%"]}}
                                    src={aboutMsg} style={{position:'absolute',width:'25%',left:'9%',top:'-23%'}} alt="" />
                                    <motion.img
                                     transition={{
                                        y: {
                                            duration:3,
                                            yoyo:Infinity,
                                            ease:"easeIn"
                                        }
                                    }}
                                    animate={{y:["5%","-25%"]}}
                                    src={aboutPhone} style={{position:'absolute',width:'7%',left:'5%',top:'70%'}} alt="" />
                                    <motion.img 
                                    transition={{
                                        y: {
                                            duration:3,
                                            yoyo:Infinity,
                                            ease:"easeIn"
                                        },
                                        rotate: {
                                            duration:20,
                                            yoyo:Infinity
                                        }
                                    }}
                                    animate={{y:["5%","-25%"],rotate:["360deg","0deg"]}}
                                    src={aboutWeb} style={{position:'absolute',width:'6%',left:'53%',top:'100%'}} alt="" />
                            </div>
                        </div>
                        <div data-aos="zoom-in" className="about-top-section-body-ctn-right-one">
                            <h2 >Our Story</h2>
                            <h3 style={{fontWeight:"600"}}>LEEYE-T®TECHNO HUB LLP 
                            <br /> Founder & CEO 
                            <br />            
                             SHAFEEQUE PARAKULATH
                            </h3>
                            <div className="about-para">

                                <p>
                                A Legacy of Expertise and Success
                                At LeEYET, we are driven by a passion for excellence and a commitment to delivering outstanding IT solutions. Our journey is led by Mr. Shafeeque Parakulath, a seasoned professional with over 16 years of experience in hardware, networking, hosting, server technology, software development, and project management.  
                                </p>
                                <p>
                                Mr. Shafeeque Parakulath's extensive knowledge and expertise have been instrumental in the successful completion of numerous projects, catering to both government and private sectors. With a deep understanding of the ever-evolving technology landscape, Mr. Shafeeque Parakulath has consistently delivered innovative solutions that align with clients' unique requirements.
                                <span>
                                    {
                                        !readMore&&
                                        <img onClick={()=>setReadMore(!readMore)} src={readMoreIcon} className="read-more-icon" alt="" />
                                    }
                                </span>
                                </p>

                                {
                                    readMore&&
                                    <div className="read-more-ctn">
                                        <p>
                                        A Proven Track Record of Success:
                                        <br />
                                        Throughout his career, Mr. Shafeeque Parakulath has demonstrated a remarkable ability to navigate complex projects and achieve exceptional results. From large-scale government initiatives to projects within the private sector, he has consistently delivered solutions that exceed expectations, driving efficiency, productivity, and growth.
                                        </p>
                                        <p>
                                        Comprehensive Project Management:
                                        <br /> Under Mr.Shafeeque Parakulath's guidance, we showcased our expertise in various domains. Our experience spans hardware infrastructure, Data center, networking solutions, hosting services, server technologies, software development, and comprehensive IT management.
                                        </p>
                                        <p>
                                        Customer-Centric Approach:
                                        <br />
                                        We firmly believe in placing our customers at the center of everything we do. Mr. Shafeeque Parakulath's leadership has instilled a customer-centric culture within our organization, ensuring that every project is approached with a focus on meeting and exceeding client expectations.    
                                        </p>
                                        <p>
                                        Continuing the Legacy of Excellence:
                                        <br />
                                        As we move forward, our commitment to excellence and delivering top-notch IT solutions remains unwavering. With Mr. Shafeeque Parakulath's leadership, we will continue to innovate, adapt to emerging technologies, and leverage our expertise to provide exceptional services to our valued clients.
                                        </p>
                                    </div>
                                }
                                 </div>
                                {
                                readMore&&
                                 <img onClick={()=>setReadMore(!readMore)} 
                                 className="read-less-icon" src={readLessIcon} alt="" />
                                }
                        </div>
                    </div>
                
                    <div className="about-top-section-body-ctn" >
                        
                        <div  data-aos="fade-down" className="about-top-section-body-ctn-left-two">
                            <h2 style={{position:'relative'}}>OUR MISSION</h2>
                            <BlinkCircle backgroundColor="#ff3643"/>
                            <h3 className="about-container-vision-head">Empowering Digital Transformation and Success</h3>
                            <p>
                                Our mission is to empower businesses and individuals with cutting-edge technology solutions that drive digital transformation and foster long-term success. We believe in harnessing the power of innovation to shape the future of industries and revolutionize the way organizations operate
                            </p>
                            <p>
                                Driving Digital Transformation: We are committed to helping businesses navigate the ever-evolving digital landscape. Our mission is to provide transformative products and services that enable companies to embrace technological advancements, optimize their operations, and thrive in the digital age.
                            </p>
                            <p>
                                Enabling Success: We are dedicated to enabling the success of our clients. By offering a comprehensive suite of solutions, we empower businesses to enhance their efficiency, improve customer experiences, and achieve their strategic goals. Your success is our ultimate goal.
                            </p>
                            
                        </div>
                        <div className="about-top-section-body-ctn-right-two">
                            <div className="about-top-section-body-ctn-right-two-colash" style={{position:'relative',height:'16vw'}}>
                                 {/* <motion.img 
                                 initial={{top:'28%',left:'63%'}}
                                 whileInView={{top:'-10%',left:'40%'}}
                                 transition={{duration:3,delay:0.3}}
                                 src={aboutLady} alt="" style={{width:'20%',position:"absolute",top:'-10%',left:'40%'}}/>       */}
                                 <motion.img 
                                 initial={{top:'56%',left:'50%'}}
                                 whileInView={{top:'-1%',left:'25%'}}
                                 transition={{duration:3,delay:0.3}}
                                 src={aboutLadyArrow} alt="" style={{width:'80%',position:"absolute",top:'-1%',left:'25%'}}/>  
                                 <motion.img
                                    initial={{left:'50%%',top:'100%'}}
                                    whileInView={{left:'23%',top:'80%'}}
                                    transition={{duration:2,type:'spring'}}
                                 src={aboutArrowSingle} alt="" style={{width:'80%',position:'absolute',top:'80%',left:'23%'}}/>       
                                 {/* <motion.img
                                    initial={{top:'56%',left:'50%'}}
                                    whileInView={{top:'16%',left:'25%'}}
                                    transition={{duration:3,delay:0.3}}
                                 src={aboutArrows} alt="" style={{width:'80%',position:'absolute',top:'16%',left:'25%'}}/>     */}
                                 <motion.img
                                 transition={{
                                    x: {
                                        duration:10,
                                        yoyo:Infinity,
                                        type:'spring'
                                    }
                                }}
                                animate={{x:["-15%","50%"]}}
                                 src={aboutCloud1} alt="" style={{width:'15%',position:'absolute',top:'65%',left:'24%'}}/>       
                                 <motion.img
                                 transition={{
                                    x: {
                                        duration:8,
                                        yoyo:Infinity,
                                        type:'spring'
                                    }
                                }}
                                animate={{x:["-20%","20%"]}}
                                 src={aboutCloud2} alt="" style={{width:'10%',position:'absolute',top:'0%',left:'65%'}}/>       
                                 <img
                                 src={aboutCloud3} alt="" style={{width:'8%',position:'absolute',top:'47%',left:'37%'}}/>       
                                 <motion.img
                                 transition={{
                                    x: {
                                        duration:8,
                                        yoyo:Infinity,
                                        type:'spring'
                                    }
                                }}
                                animate={{x:["20%","-20%"]}}
                                 src={aboutCloud4} alt="" style={{width:'8%',position:'absolute',top:'40%',left:'63%'}}/>       
                                 <motion.img
                                 transition={{
                                    x: {
                                        duration:8,
                                        yoyo:Infinity,
                                        type:'spring'
                                    }
                                }}
                                animate={{x:["30%","-30%"]}}
                                 src={aboutCloud5} alt="" style={{width:'10%',position:'absolute',top:'25%',left:'30%'}}/>       
                                     
                            </div>  
                        </div>
                    </div>
                    <div className="about-top-section-body-ctn">
                    <img src={aboutBackground} className="about-background-img"  alt="" />
                        <div className="about-top-section-body-ctn-left-one-sec">
                            <div className="about-top-section-body-ctn-left-one-sec-colash" style={{position:'relative',marginRight:'15%',marginTop:'15%'}}>
                                <motion.img
                                initial={{rotate:'10deg'}}
                                whileInView={{rotate:'0deg'}}
                                transition={{duration:0.6,delay:1.1}}
                                src={aboutSparkleArchery} alt="" style={{width:'55%'}}/>
                                <motion.img 
                                    initial={{top:'-50%',left:'70%'}}
                                    whileInView={{top:'-20%',left:'51%'}}
                                    transition={{duration:1.5}}
                                src={aboutSparkleArrow1} alt="" style={{width:'50%',position:'absolute',top:'-20%',left:'51%'}}/>
                                <motion.img
                                initial={{top:'-50%',left:'60%'}}
                                whileInView={{top:'-5%',left:'34%'}}
                                transition={{duration:1.5}}
                                src={aboutSparkleArrow2} alt="" style={{width:'80%',position:'absolute',top:'-5%',left:'34%'}}/>
                                <motion.img
                                transition={{
                                    opacity: {
                                        duration:2,
                                        yoyo:Infinity,
                                        type:'spring'
                                    }
                                }}
                                animate={{opacity:["100%","0%"]}}
                                src={aboutSparkleDiamond1} alt="" style={{position:'absolute',top:'60%',left:'20%'}}/>
                                <motion.img
                                transition={{
                                    opacity: {
                                        duration:3,
                                        yoyo:Infinity,
                                        type:'spring'
                                    }
                                }}
                                animate={{opacity:["50%","100%"]}}
                                src={aboutSparkleDiamond2} alt="" style={{position:'absolute',top:'2%',left:'64%'}}/>
                                <motion.img
                                transition={{
                                    opacity: {
                                        duration:1.7,
                                        yoyo:Infinity,
                                        type:'spring'
                                    }
                                }}
                                animate={{opacity:["100%","0%"]}}
                                src={aboutSparkleDiamond3} alt="" style={{position:'absolute',top:'47%',left:'80%'}}/>
                                <motion.img
                                transition={{
                                    opacity: {
                                        duration:1.2,
                                        yoyo:Infinity,
                                        type:'spring'
                                    }
                                }}
                                animate={{opacity:["0%","100%"]}}
                                src={aboutSparkleDiamond4} alt="" style={{position:'absolute',top:'55%',left:'88%'}}/>
                                <motion.img 
                                transition={{
                                    x: {
                                        duration:4,
                                        yoyo:Infinity,
                                        ease:"easeOut"
                                    }
                                }}
                                animate={{x:["100%","0%"]}}
                                src={aboutSparkleDiamondMedium} alt="" style={{width:'2%',position:'absolute',top:'54%',left:'16%'}}/>
                                <motion.img
                                transition={sparkleDiamondSmallTransition}
                                animate={sparkleDiamondSmallAnimation}
                                src={aboutSparkleDiamondSmall1} alt="" style={{position:'absolute',top:'25%',left:'16%'}}/>
                                <motion.img 
                                transition={sparkleDiamondSmallTransition}
                                animate={sparkleDiamondSmallAnimation}
                                src={aboutSparkleDiamondSmall2} alt="" style={{position:'absolute',top:'15%',left:'70%'}}/>
                                <motion.img 
                                transition={sparkleDiamondSmallTransition}
                                animate={sparkleDiamondSmallAnimation}
                                src={aboutSparkleDiamondSmall3} alt="" style={{position:'absolute',top:'-10%',left:'80%'}}/>
                                <motion.img 
                                transition={sparkleDiamondSmallTransition}
                                animate={sparkleDiamondSmallAnimation}
                                src={aboutSparkleDiamondSmall4} alt="" style={{position:'absolute',top:'25%',left:'100%'}}/>
                                <motion.img
                                transition={sparkleDotTransition}
                                animate={sparkleDotLargeAnimation}
                                src={aboutSparkleDot1} alt="" style={{position:'absolute',top:'66%',left:'13%'}}/>
                                <motion.img
                                transition={sparkleDotTransition}
                                animate={sparkleDotLargeAnimation}
                                src={aboutSparkleDot2} alt="" style={{position:'absolute',top:'75%',left:'85%'}}/>
                                <motion.img
                                transition={sparkleDotMediumTransition}
                                animate={sparkleDotMediumAnimation}
                                src={aboutSparkleDotMedium1} alt="" style={{position:'absolute',top:'78%',left:'28%'}}/>
                                <motion.img
                                transition={sparkleDotMediumTransition}
                                animate={sparkleDotMediumAnimation}
                                src={aboutSparkleDotMedium2} alt="" style={{position:'absolute',top:'72%',left:'16%'}}/>
                                <motion.img
                                transition={sparkleDotMediumTransition}
                                animate={sparkleDotMediumAnimation}
                                src={aboutSparkleDotMedium3} alt="" style={{position:'absolute',top:'41%',left:'15%'}}/>
                                <motion.img
                                transition={sparkleDotMediumTransition}
                                animate={sparkleDotMediumAnimation}
                                src={aboutSparkleDotMedium4} alt="" style={{position:'absolute',top:'5%',left:'26%'}}/>
                                <motion.img
                                transition={sparkleDotMediumTransition}
                                animate={sparkleDotMediumAnimation}
                                src={aboutSparkleDotMedium5} alt="" style={{position:'absolute',top:'65%',left:'83%'}}/>
                                <motion.img
                                transition={sparkleDotMediumTransition}
                                animate={sparkleDotMediumAnimation}
                                src={aboutSparkleDotMedium6} alt="" style={{position:'absolute',top:'35%',left:'76%'}}/>
                                <motion.img
                                transition={sparkleDotMediumTransition}
                                animate={sparkleDotMediumAnimation}
                                src={aboutSparkleDotMedium7} alt="" style={{position:'absolute',top:'-5%',left:'56%'}}/>
                                <motion.img
                                transition={sparkleDotMediumTransition}
                                animate={sparkleDotMediumAnimation}
                                src={aboutSparkleDotMedium8} alt="" style={{position:'absolute',top:'-6%',left:'90%'}}/>
                                <motion.img
                                transition={sparkleDotMediumTransition}
                                animate={sparkleDotMediumAnimation}
                                src={aboutSparkleDotMedium9} alt="" style={{position:'absolute',top:'2%',left:'91%'}}/>

                                <motion.img
                                transition={sparkleDotTransition}
                                animate={sparkleDotAnimation}
                                src={aboutSparkleDotSmall1} alt="" style={{position:'absolute',top:'28%',left:'18%'}}/>
                                <motion.img
                                transition={sparkleDotTransition}
                                animate={sparkleDotAnimation}
                                src={aboutSparkleDotSmall2} alt="" style={{position:'absolute',top:'42%',left:'16%'}}/>
                                <motion.img
                                transition={sparkleDotTransition}
                                animate={sparkleDotAnimation}
                                src={aboutSparkleDotSmall3} alt="" style={{position:'absolute',top:'68%',left:'23%'}}/>
                                <motion.img
                                transition={sparkleDotTransition}
                                animate={sparkleDotAnimation}
                                src={aboutSparkleDotSmall4} alt="" style={{position:'absolute',top:'70%',left:'22%'}}/>
                                <motion.img
                                transition={sparkleDotTransition}
                                animate={sparkleDotAnimation}
                                src={aboutSparkleDotSmall5} alt="" style={{position:'absolute',top:'-7%',left:'57%'}}/>
                                <motion.img
                                transition={sparkleDotTransition}
                                animate={sparkleDotAnimation}
                                src={aboutSparkleDotSmall6} alt="" style={{position:'absolute',top:'33%',left:'77%'}}/>
                                <motion.img
                                transition={sparkleDotTransition}
                                animate={sparkleDotAnimation}
                                src={aboutSparkleDotSmall7} alt="" style={{position:'absolute',top:'27%',left:'84%'}}/>
                                <motion.img
                                transition={sparkleDotTransition}
                                animate={sparkleDotAnimation}
                                src={aboutSparkleDotSmall8} alt="" style={{position:'absolute',top:'1%',left:'90%'}}/>
                                <motion.img
                                transition={sparkleDotTransition}
                                animate={sparkleDotAnimation}
                                src={aboutSparkleDotSmall9} alt="" style={{position:'absolute',top:'30%',left:'100%'}}/>
                            </div>
                            
                            
                        </div>
                        <div data-aos="fade-down" className="about-top-section-body-ctn-right-one">
                            <h2 style={{position:'relative'}}>OUR VISION</h2>
                            <BlinkCircle backgroundColor="rgb(0 35 207)" topPosition='20%' leftPosition='-10.8%'/>
                            <h3 className="about-container-vision-head">Pioneering Innovation for a Connected World</h3>
                            <p >Our vision is to be a pioneering force in driving innovation and connecting the world through transformative technology solutions. We envision a future where businesses and individuals thrive in a seamlessly interconnected digital ecosystem, empowered by cutting-edge advancements and boundless possibilities.
                            </p>
                            <p>
                            Advancing Technological Frontiers: Our vision is to push the boundaries of technology, constantly seeking new horizons and staying at the forefront of innovation. We aspire to lead the way in discovering and harnessing emerging technologies that revolutionize industries and reshape the world we live in.
                            </p>
                            <p>
                            Empowering Individuals: Our vision extends beyond businesses to empower individuals. We strive to create technology solutions that enhance everyday lives, inspire creativity, and improve overall well-being. We envision individuals leveraging technology to unlock their full potential and thrive in an increasingly digital world.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="about-bottom-section">
                    <div className="bottom-section-head-ctn">
                        <div className="bottom-section-head-ctn-left">
                                 
                            <div data-aos="fade-right"  className="bottom-section-head-ctn-left-top">
                                <h2 style={{position:'relative'}}>BUILD YOUR OWN EMPIRE</h2>
                                <BlinkCircle backgroundColor="rgb(68 199 5)" topPosition='10%'/>
                                <p>
                                Partnering with LeEYET Techno Hub means gaining a trusted advisor and collaborator dedicated to your success. We work closely with our clients, understanding their goals and challenges, and providing tailored solutions that deliver tangible results.
                                </p>
                                <p>
                                Explore our services, learn about our success stories, and join the ranks of businesses that have transformed with LeEYET Techno Hub. Together, let's embrace the power of technology and embark on a journey of growth, innovation, and digital excellence.                                        
                                </p>
                                    
                            </div>
                            <div className="bottom-section-head-ctn-left-bottom"  style={{position:'relative'}}>
                            <motion.img
                            transition={{
                                y: {
                                    duration:8,
                                    yoyo:Infinity,
                                    type:'spring'
                                }
                            }}
                            animate={{y:["-5%","-15%"]}}                           
                            src={PaperPlaneSvg} style={{position:'absolute',width:'100%',top:'25%',left:'-14%'}} alt="" />
                            {/* <motion.img
                            initial={{top:'45%'}}
                            whileInView={{top:'25%'}}
                            transition={{duration:3,delay:1.1}}
                            src={PlanepathSvg} style={{position:'absolute',width:'100%',top:'25%',left:'-14%'}} alt="" />    */}

                            {/* <motion.svg 
                            variants={svgVariants}
                            initial="hidden"
                            animate="visible"
                            style={{position:'absolute',width:'100%',top:'11%',left:'-14%'}} xmlns="http://www.w3.org/2000/svg" width="1027.249" height="787.842" viewBox="0 0 1027.249 787.842">
                            <motion.path
                            variants={pathVariants}
                            id="Path_1288" data-name="Path 1288" d="M-8945.183-545.939s98.224,195.876,246.7,139.34,79.378-245.559,267.259-299.239,352.919,209.01,484.264,84.518-248.985-468.274-100.508-561.929" transform="translate(8945.63 1183.672)" fill="none" stroke="#707070" stroke-width="1" stroke-dasharray="9" opacity="0.67"/>
                            </motion.svg> */}
     
                                <div className="bottom-section-head-ctn-left-bottom-pink"></div>
                                <div className="bottom-section-head-ctn-left-bottom-white">
                                    <img src={buildingImg} alt="" />
                                </div>
                            </div>

                        </div>
                        <div className="bottom-section-head-ctn-right">
                            <div className="bottom-section-head-ctn-right-top">
                                <div className="bottom-section-head-ctn-right-top-pink"></div>
                                <div className="bottom-section-head-ctn-right-top-white">
                                    <img src={paperBoat} alt="" />
                                </div>
                            </div>
                            <div data-aos="fade-left"  className="bottom-section-head-ctn-right-bottom">
                                <h2 style={{position:'relative'}}>OPPURTUNITIES</h2>
                                <BlinkCircle backgroundColor="rgb(0 177 213)" topPosition='6%' leftPosition='-19.2%'/>
                                <p>LeEYE-T®is focus to deliver high quality, cost-effective, time bound 
                                and value added services to meet a wide range of requirements in 
                                order to help clients yield greater return on investment for 
                                their technology initiatives.
                                </p>    
                            </div>
                        </div>
                    </div>


                    <div className="bottom-section-body-ctn">
                        <div data-aos="zoom-in"  className="bottom-section-body-ctn-left">
                            <h3 >Thank you,</h3>
                            <p>Thank You for Choosing LeEYET
                            <br /> At LeEYET, we would like to express our sincere gratitude for choosing our services/products. We are honored to have the opportunity to serve you and meet your needs. Your trust and support mean the world to us, and we strive to exceed your expectations at every step. On behalf of the entire team at LeEYET Techno hub, we want to extend our heartfelt gratitude for your support, trust, and partnership. We are excited to continue serving you and look forward to a long and fruitful relationship. Thank you for being a valued member of our community. 
                            </p>
                        </div>
                        <div data-aos="fade-right" className="bottom-section-body-ctn-right">
                           
                                <div data-aos="zoom-in"  className="bottom-section-body-ctn-right-parent">
                                    <img src={ceoImg} alt="" />
                                    <h3 style={{marginLeft:"10%"}}>SHAFEEQUE PARAKULATH</h3>
                                    <p style={{marginLeft:"10%"}}>Founder & CEO</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            <Footer/>
        </>
    );
}

export default About;   