import React from "react";
import "../customComponents/upClick.css"
import upClickImg from "../../Assets/Elements/HomePage/UpClickGif.gif"
import { HashLink } from "react-router-hash-link";
const UpClick=(props)=>{

    const {topPosition}=props
    return(
        <HashLink smooth to="/#top" className="up-click-ctn" style={{top:topPosition}} >  
        <img src={upClickImg} alt="" />
        </HashLink>
    );
};

export default UpClick;