import React, { useEffect } from "react";
import "../../CSS/Careers/careerscard.css"
import Aos from "aos";
import "aos/dist/aos.css"
import { motion } from "framer-motion";
const CareersCard=(props)=>{
    const {jobTitle,experienceDuration,jobType,jobDescription,applyNowClick}=props;
    useEffect(()=>{
        Aos.init({duration:1000});
    },[])

    return(
        <>
           
            <motion.div 
            initial={{y:-50}}
            whileInView={{y:0}}
            transition={{duration:1}}
            className="careers-card-ctn">
            <div className="careers-card-ctn-head">
                    <h3>{jobTitle}</h3>
                    <p>{experienceDuration}</p>
                    <p className="right-edge">{jobType}</p>
            </div>
            <div className="careers-card-ctn-body">
                    <motion.ul
                    initial={{opacity:0}}
                    whileInView={{opacity:1}}
                    transition={{duration:1,delay:0.2}}>
                        {
                            jobDescription?.length!==0 &&
                            jobDescription?.map((r,i)=>(
                                <li key={i}>{r}</li>
                            ))
                        }
                        {/* <li>End-to-end design of the user interface for web and mobile applications</li>
                        <li>Conduct user research and evaluate user feedback</li>
                        <li>Work hand-in-hand with development and marketing teams to meet 
                            project requirements within set deadlines</li>
                        <li>Create original graphic designs (e.g. images, sketches, and tables)</li>
                        <li>Illustrate design ideas using storyboards, process flows, and sitemaps</li> */}
                    </motion.ul>
            </div>
            <div className="careers-card-ctn-footer">
                    <button onClick={applyNowClick}>Apply Now</button>
            </div>
        </motion.div>
        </>
    );
};

export default CareersCard;